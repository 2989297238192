import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { useApi } from './useApi';
import { API_METHOD_DELETE, API_METHOD_GET, API_METHOD_PUT } from '../constants/api';
import { LOGIN_PAGE, MY_ACCOUNT_API, PROFESSIONS } from '../constants/routes';
import {
  getMyAccountInstituteValidationSchema,
  getMyAccountIsCompanyValidationSchema,
  getMyAccountValidationSchema,
} from '../helpers/validationSchemaHelper';
import useYupValidationResolver from './useYupValidationResolver';
import { IMyAccountForm } from '../interfaces/components/MyAccount';
import { IReduxStore } from '../interfaces/IGeneral';
import { SET_PROFILE } from '../constants/reduxActions';
import { scrollToError } from '../helpers/baseHelper';
import { IProfession } from '../interfaces/components/Profession';
import useGetTranslations from './useGetTranslations';
import useGeneral from './useGeneral';
import { TOKEN_DATA_KEY } from '../constants/login';
import { ACTIVE_COMPANY_PROFESSION_TYPES, ACTIVE_PRIVATE_PROFESSION_TYPES } from '../constants/register';

function useMyAccount() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [professionsTypes = [], setProfessionsTypes] = useState<Array<{ title: string; id: number }>>();
  const [filteredProfessionsTypes, setFilteredProfessionsTypes] = useState<Array<{ title: string; id: number }>>([]);
  const { profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [selectedProfessionTypes = [], setSelectedProfessionTypes] = useState<Array<number>>();
  const { handleApi } = useApi();
  const dispatch = useDispatch();
  const { translations } = useGetTranslations();
  const { handleRedirect } = useGeneral();

  // prettier-ignore
  const resolver = useYupValidationResolver(
    profile?.userType === 'private'
      ? getMyAccountValidationSchema()
      : profile?.userType === 'company'
        ? getMyAccountIsCompanyValidationSchema()
        : getMyAccountInstituteValidationSchema()
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setMyAccountValue,
    control,
  } = useForm<IMyAccountForm>({ resolver });

  useEffect(() => {
    setProfileData();
    getProfessions();
  }, []);

  useEffect(() => {
    setProfileData();
  }, [profile]);

  const setProfileData = () => {
    setMyAccountValue('email', profile?.email);
    setMyAccountValue('firstName', profile?.firstName);
    setMyAccountValue('lastName', profile?.lastName);
    setMyAccountValue('companyName', profile?.companyName);
    setMyAccountValue('companyTaxNumber', profile?.companyTaxNumber);
    setMyAccountValue('country', profile?.country);
    setMyAccountValue('region', profile?.region);
    setMyAccountValue('postcode', profile?.postcode);
    setMyAccountValue('city', profile?.city);
    setMyAccountValue('address1', profile?.address1);
    setMyAccountValue('address2', profile?.address2);
    setMyAccountValue('instituteName', profile?.instituteName);
    setIsPrivate(profile?.userType === 'private');
    setSelectedProfessionTypes(getSelectedProfessionIds(profile?.professions || []));
  };

  const filterProfessionTypes = (data: any) => {
    const activeProfessions = !isPrivate ? ACTIVE_PRIVATE_PROFESSION_TYPES : ACTIVE_COMPANY_PROFESSION_TYPES;
    const filteredProfessions = data.filter((profession: any) => activeProfessions.includes(profession.title));
    setFilteredProfessionsTypes(filteredProfessions);
  };

  const getSelectedProfessionIds = (selectedProfessions: IProfession[]): number[] => {
    const professions: number[] = [];
    selectedProfessions.map((selectedProfession: IProfession) => {
      professions.push(selectedProfession.id);
    });
    return professions;
  };

  const getSelectedProfessionTypes = (professions: number[]): IProfession[] => {
    const selectedProfessionsTypes: IProfession[] = [];
    professionsTypes.map((profession: IProfession) => {
      if (professions.includes(profession.id)) selectedProfessionsTypes.push(profession);
    });
    selectedProfessionsTypes.filter((profession: IProfession | undefined) => profession !== undefined);

    return selectedProfessionsTypes;
  };

  async function getProfessions() {
    const { data } = await handleApi(API_METHOD_GET, PROFESSIONS);
    filterProfessionTypes(data);
    setProfessionsTypes(data);
  }

  async function submitMyAccount(formData: IMyAccountForm) {
    const { password, passwordConfirmation, ...sendData } = formData;

    if (selectedProfessionTypes.length === 0 && profile?.userType !== 'institute') {
      toast.error(translations.professionsError);
      return;
    }
    const { message, code } = await handleApi(API_METHOD_PUT, MY_ACCOUNT_API, {
      ...(password === null && passwordConfirmation === '' ? { ...sendData } : { ...formData }),
      userType: profile?.userType === 'institute' ? 'institute' : isPrivate ? 'private' : 'company',
      professions: selectedProfessionTypes,
    });
    if (code === 200) {
      setIsEditing(false);
      window.scroll(0, 0);
      dispatch({
        type: SET_PROFILE,
        payload: {
          ...formData,
          professions: getSelectedProfessionTypes(selectedProfessionTypes),
          userType: profile?.userType === 'institute' ? 'institute' : isPrivate ? 'private' : 'company',
        },
      });
      toast.success(translations.profileSaveSuccess);
      return;
    }
    if (errors) {
      scrollToError(errors);
    }
    toast.error(message);
  }

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const toggleIsPrivate = () => {
    setIsPrivate(!isPrivate);
    setSelectedProfessionTypes([]);
    filterProfessionTypes(professionsTypes);
  };

  const toggleSelectedProfessions = (id: number) => {
    if (isPrivate) {
      setSelectedProfessionTypes([id]);
      return;
    }
    const ids = selectedProfessionTypes.includes(id)
      ? selectedProfessionTypes.filter((element) => element != id)
      : [...selectedProfessionTypes, id];
    setSelectedProfessionTypes(ids);
  };

  async function deleteAccount() {
    await handleApi(API_METHOD_DELETE, MY_ACCOUNT_API);
    setIsEditing(false);
    setIsModalVisible(false);
    localStorage.removeItem(TOKEN_DATA_KEY);
    handleRedirect(LOGIN_PAGE);
  }

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return {
    toggleEditing,
    isEditing,
    professionsTypes: filteredProfessionsTypes,
    errors,
    register,
    handleSubmit: handleSubmit(submitMyAccount),
    profile,
    isPrivate,
    toggleIsPrivate,
    toggleSelectedProfessions,
    selectedProfessionTypes,
    deleteAccount,
    isModalVisible,
    toggleModal,
    control,
  };
}

export default useMyAccount;
