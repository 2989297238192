import styles from '../assets/scss/components/IdHeader.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import useGetTranslation from '../hooks/useGetTranslations';
import { professionTranslation } from '../utils/roleChecker';

type IdHeaderProps = {
  id?: number;
  isInstrument?: boolean;
};

function IdHeader({ id, isInstrument }: IdHeaderProps): JSX.Element {
  const { wrapper } = convertKeysToCamelCase(styles);
  const { translations } = useGetTranslation();

  const idLabel = isInstrument ? translations.instrumentId : translations[professionTranslation('clientId', true)];

  return (
    <div className={`${wrapper} h4`}>
      {idLabel}: {id || ''}
    </div>
  );
}

export default IdHeader;
