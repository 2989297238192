import { useDispatch, useSelector } from 'react-redux';
import ServiceProjectsHeader from '../ServiceProjectsHeader';
import FilterElement from '../FilterElement';
import HomeSearchHeader from '../HomeSearchHeader';
import { INSTRUMENT_SERVICES, PROJECT_DETAIL } from '../../constants/routes';
import GlobalSearchElement from '../GlobalSearchElements';
import AdSection from '../AdSection';
import useGetTranslations from '../../hooks/useGetTranslations';
import { IProjectListElement, ISimpleProject } from '../../interfaces/components/Project';
import { IReduxStore } from '../../interfaces/IGeneral';
import useGetProjects from '../../hooks/useGetProjects';
import InfiniteScroll from 'react-infinite-scroll-component';
import { filterDuplicateNames } from '../../helpers/baseHelper';
import useMobile from '../../hooks/useMobile';
import { paddingForInfiniteScroll } from '../../constants/infiniteScrollStyle';
import useGeneral from '../../hooks/useGeneral';
import { SET_ORDER } from '../../constants/reduxActions';
import { InstrumentServiceCard } from '../InstrumentServiceCard';
import ActiveProjectCard from '../ActiveProjectCard';
import { ProjectIconInfo } from '../ProjectIconInfo';
import useChooseTuner from '../../hooks/useChooseTuner';
import { useMemo } from 'react';
import { professionChecker } from '../../utils/roleChecker';
import useSetHeader from '../../hooks/useSetHeader';

function Projects(): JSX.Element {
  const {
    projects,
    total,
    projectsClients,
    projectsInstruments,
    nextPage,
    activeProjects,
    activeProjectsChecked,
    handleActiveProjectsChecked,
  } = useGetProjects();
  const { globalSearch, profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const { isMobile } = useMobile();
  const { handleRedirect } = useGeneral();
  const dispatch = useDispatch();
  const { tuners } = useChooseTuner();
  const isTuner = useMemo(() => professionChecker(), []);

  const userData = localStorage.getItem('userData');
  const title = userData ? userData.split(',')[0] : 'Piano Service App';

  useSetHeader(() => {}, title, undefined, false, true);

  //prettier-ignore
  return (
    <main>
      <HomeSearchHeader
        hideFilters={!isTuner}
        sortField="brand"
        parentPage={activeProjectsChecked ? "activeProjectList" : "projectList"}
        sortTitle={{
          ascending: translations.sortInstrumentsAscending,
          descending: translations.sortInstrumentsDescending,
        }}
        passAllSortToActiveFilter
        filters={
          <>
            <FilterElement
              name={translations.byTuner}
              data={filterDuplicateNames(tuners)}
              field="tuners"
              isNumber={true}
            />
            <FilterElement
              name={translations.byInstrument}
              data={filterDuplicateNames(
                projectsInstruments.map((instrument) => ({
                  id: instrument.id,
                  name: instrument.brand,
                }))
              )}
              field="instruments"
              isNumber={false}
            />
            <FilterElement
              name={ profile?.userType !== 'institute' ? translations.byClients : translations.byInstituteClient}
              data={filterDuplicateNames(
                projectsClients.map((cli) => ({ id: cli.id, name: `${cli.firstName} ${cli.lastName}, ID: ${cli.id}` }))
              )}
              field="clients"
              isNumber={true}
            />
            <FilterElement
              name={translations.byCity}
              data={filterDuplicateNames(projectsClients.map((cli) => ({ id: cli.id, name: cli.city })))}
              field="city"
              isNumber={true}
            />
            <FilterElement
              name={translations.byCountry}
              data={filterDuplicateNames(projectsClients.map((cli) => ({ id: cli.id, name: cli.country })))}
              field="country"
              isNumber={true}
            />
          </>
        }
      />
      {Object.keys(globalSearch).length > 0 ? (
        <GlobalSearchElement />
      ) : (
        <>
          <section className="small">
            <ServiceProjectsHeader isServices={false} toggleChecked={activeProjectsChecked} onToggleChecked={handleActiveProjectsChecked} />
          </section>
          <AdSection length={total} noPadding>
            <ProjectIconInfo active={activeProjectsChecked}  />
            {activeProjectsChecked ? <>{isMobile ? (
              <InfiniteScroll
                dataLength={projects.length}
                next={nextPage}
                hasMore={true}
                loader={<></>}
                style={{...paddingForInfiniteScroll, marginTop: '20px'}}
              >
                {activeProjects?.map((project: ISimpleProject) => (
                  <ActiveProjectCard project={project} key={`active-project-${project.id}`} onClick={() => {
                    dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
                    handleRedirect(PROJECT_DETAIL.replace(':id', project.id.toString()));
                  }}/>
                ))}
              </InfiniteScroll>
            ) : (
              <div style={{marginTop: '20px'}}>
                {activeProjects?.map((project: ISimpleProject) => (
                  <ActiveProjectCard project={project} key={`active-project-${project.id}`} onClick={() => {
                    dispatch({ type: SET_ORDER, payload: { orderByField: 'last_date', orderByDirection: 'DESC' } });
                    handleRedirect(PROJECT_DETAIL.replace(':id', project.id.toString()));
                  }}/>
                ))} 
              </div>
            )}</> : <><>{isMobile ? (
              <InfiniteScroll
                dataLength={projects.length}
                next={nextPage}
                hasMore={true}
                loader={<></>}
                style={paddingForInfiniteScroll}
              >
                {projects?.map((project: IProjectListElement) => (
                  <InstrumentServiceCard
                    key={project.id}
                    isSimple={false}
                    isServiceCard
                    dateInsteadModel
                    date={project.endingDate}
                    endDate={project.startingDate}
                    serviceType="project"
                    {...project}
                    to={INSTRUMENT_SERVICES.replace(':id', project.id.toString())}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              projects?.map((project: IProjectListElement) => (
                <InstrumentServiceCard
                  key={project.id}
                  isSimple={false}
                  isServiceCard
                  dateInsteadModel
                  date={project.endingDate}
                  endDate={project.startingDate}
                  serviceType="project"
                  {...project}
                  to={INSTRUMENT_SERVICES.replace(':id', project.id.toString())}
                />
              ))
            )}</></> }
          </AdSection>
        </>
      )}
    </main>
  );
}

export default Projects;
