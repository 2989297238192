import AttachmentHeader from '../AttachmentHeader';
import InstrumentCard from '../InstrumentCard';
import SwitcherComponent from '../SwitcherComponent';
import useAttachments from '../../hooks/useAttachments';
import AttachmentImages from '../AttachmentImages';
import AdSection from '../AdSection';
import Title from '../Title';
import AttachmentAttachments from '../AttachmentAttachments';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import IdHeader from '../IdHeader';
import AlertModal from '../AlertModal';
import { ImageCropper } from '../ImageCropper';
import { FaExclamation } from 'react-icons/fa';
import style from '../../assets/scss/components/AttachmentHeader.module.scss';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import { MenuBar } from '../MenuBar';
import { BsFillPersonFill } from 'react-icons/bs';
import { GiAutoRepair } from 'react-icons/gi';
import { BiCalendar } from 'react-icons/bi';
import { MdPiano } from 'react-icons/md';
import { CLIENT_DETAILS, INSTRUMENT_DETAILS, INSTRUMENT_SERVICES, NEW_CLIENT_PAGE } from '../../constants/routes';
import { SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER } from '../../constants/reduxActions';
import { useDispatch } from 'react-redux';
import useGeneral from '../../hooks/useGeneral';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';

function Attachments(): JSX.Element {
  const {
    instrument,
    toggleEditing,
    selectedImages,
    onImageClick,
    imageModal,
    closeModal,
    selectedAttachments,
    onAttachmentClick,
    handleDelete,
    switcherPage,
    toggleSwitcher,
    type,
    handleUpload,
    projectFiles,
    isDeleteModalOpen,
    toggleDeleteModal,
    cropperRef,
    cropImage,
    handleFinishCrop,
    dataTotal,
    data,
    nextPage,
    files,
    handleCancelCrop,
  } = useAttachments();
  const { warningWrapper, infoText } = convertKeysToCamelCase(style);
  const dispatch = useDispatch();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslations();
  const { canCreateNewClient } = useMySubscriptionPlan();

  useSetHeader(() => {}, translations.attachmentDetailTitle);

  //prettier-ignore
  return (
    <main>
      <AttachmentHeader
        onChange={handleUpload}
        isEditing={false}
        toggleEditing={toggleEditing}
        handleDelete={toggleDeleteModal}
        switcherPage={switcherPage}
      />
      <AlertModal
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        header={translations.deleteModalTitle}
        description=""
        leftButton={translations.back}
        rightButton={translations.deleteSelected}
        onLeftButtonClick={toggleDeleteModal}
        onRightButtonClick={handleDelete}
      />
      <AdSection topPadding>
        <>
          {instrument?.id && <IdHeader isInstrument={true} id={instrument.id} />}
          {(type === 'instrument' || type === 'service' || type === 'project') && (
            <InstrumentCard isSimple={true} {...instrument} noMarginBottom />
          )}
          <MenuBar
            items={[
              {
                icon: <MdPiano />,
                onClick: () => {
                  if (!instrument || !instrument.id) {
                    return;
                  }
                  handleRedirect(INSTRUMENT_DETAILS.replace(':id', instrument.id.toString()));
                },
              },
              {
                icon: <BsFillPersonFill />,
                onClick: () => {
                  if (!instrument || !instrument.id) {
                    return;
                  }
                  if (!instrument.clientId) {
                    if (!canCreateNewClient()) {
                      toast.error(<LimitExceededNotification />, limitExceededOptions);
                      return;
                    }
                    handleRedirect(NEW_CLIENT_PAGE + `?instrumentId=${instrument.id}`);
                    return;
                  } else {
                    handleRedirect(CLIENT_DETAILS.replace(':id', instrument.clientId.toString()));
                  }
                },
              },
              {
                icon: <GiAutoRepair />,
                onClick: () => {
                  if (!instrument || !instrument.id) {
                    return;
                  }
                  dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 0 });
                  handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
                },
              },
              {
                icon: <BiCalendar />,
                onClick: () => {
                  if (!instrument || !instrument.id) {
                    return;
                  }
                  dispatch({ type: SET_INSTRUMENTS_SERVICES_PAGE_SWITCHER, payload: 2 });
                  handleRedirect(INSTRUMENT_SERVICES.replace(':id', instrument.id.toString()));
                },
              },
            ]}
          />

          <div>
            {switcherPage === 0 && cropImage !== '' && (
              <ImageCropper
                cropperRef={cropperRef}
                cropImage={cropImage}
                handleFinishCrop={handleFinishCrop}
                cropText={translations.cropAndUpload}
                cancelText={translations.cancel}
                handleCancel={handleCancelCrop}
              />
            )}
          </div>
          <div className={warningWrapper}>
            <FaExclamation color="#8b0000" />
            <p className={infoText}>{translations.slowUpload}</p>
          </div>
          {!projectFiles && (
            <SwitcherComponent
              switcherPage={switcherPage}
              toggleSwitcherPage={toggleSwitcher}
              titles={[
                instrument
                  ? `${translations.attachmentPictureTitle} ` + `(${instrument && instrument?.imagesCount})`
                  : translations.attachmentPictureTitle,
                instrument
                  ? `${translations.attachmentFileTitle} (${instrument && instrument?.filesCount})`
                  : translations.attachmentFileTitle,
              ]}
              elements={[
                <AttachmentImages
                  key="AttachmentImages"
                  isEditing={false}
                  files={type === 'instrument' ? data : files?.attachments.filter((f) => f.selectedType === 'image')}
                  total={dataTotal}
                  selectedImages={selectedImages}
                  onImageClick={onImageClick}
                  imageModal={imageModal}
                  closeModal={closeModal}
                  projectFiles={projectFiles}
                  nextPage={type === 'instrument' ? nextPage : undefined}
                />,
                <AttachmentAttachments
                  key="AttachmentAttachments"
                  isEditing={false}
                  files={type === 'instrument' ? data : files?.attachments.filter((f) => f.selectedType !== 'image')}
                  total={dataTotal}
                  selectedAttachments={selectedAttachments}
                  onAttachmentClick={onAttachmentClick}
                  projectFiles={projectFiles}
                  nextPage={type === 'instrument' ? nextPage : undefined}
                />,
              ]}
            />
          )}
          {projectFiles && (
            <SwitcherComponent
              switcherPage={switcherPage}
              toggleSwitcherPage={toggleSwitcher}
              titles={[
                instrument
                  ? `${translations.attachmentPictureTitle} ` + `(${instrument && instrument?.imagesCount})`
                  : translations.attachmentPictureTitle,
                instrument
                  ? `${translations.attachmentFileTitle} (${instrument && instrument?.filesCount})`
                  : translations.attachmentFileTitle,
              ]}
              elements={[
                <div key="attachments-1">
                  {projectFiles.services.map(
                    (serviceFiles) =>
                      serviceFiles.attachments.filter((file) => file.selectedType === 'image').length > 0 && (
                        <div key={serviceFiles.id}>
                          <Title title={serviceFiles.title} />
                          <AttachmentImages
                            key={serviceFiles.id}
                            isEditing={false}
                            files={serviceFiles.attachments.filter((file) => file.selectedType === 'image')}
                            selectedImages={selectedImages}
                            onImageClick={onImageClick}
                            imageModal={imageModal}
                            closeModal={closeModal}
                            projectFiles={projectFiles}
                          />
                        </div>
                      )
                  )}
                </div>,
                <div key="attachments-2">
                  {projectFiles.services.map(
                    (serviceFiles) =>
                      serviceFiles.attachments.filter((file) => file.selectedType === 'file').length > 0 && (
                        <div key={serviceFiles.id}>
                          <Title title={serviceFiles.title} />
                          <AttachmentAttachments
                            key={serviceFiles.id}
                            isEditing={false}
                            files={serviceFiles.attachments.filter((file) => file.selectedType === 'file')}
                            selectedAttachments={selectedAttachments}
                            onAttachmentClick={onAttachmentClick}
                            projectFiles={projectFiles}
                          />
                        </div>
                      )
                  )}
                </div>,
              ]}
            />
          )}
        </>
      </AdSection>
    </main>
  );
}

export default Attachments;
