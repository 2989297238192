import * as yup from 'yup';
import { RegisterType } from '../interfaces/IGeneral';

export function getSignInValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    email: yup.string().required('required').email('email'),
    password: yup.string().required('required'),
  };

  return yup.object().shape(schemaObj);
}

export function getRegistrationValidationSchema(registerType: RegisterType | undefined): yup.ObjectSchema<any> {
  const schemaObj = {
    firstName: registerType !== 'institute' ? yup.string().required('required') : yup.string(),
    lastName: registerType !== 'institute' ? yup.string().required('required') : yup.string(),
    email: yup.string().required('required').email('email'),
    password: yup.string().required('required').min(8),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    address1: yup.string().required('required'),
    address2: yup.string().notOneOf([yup.ref('address1')], 'differentAddress'),
    city: yup.string().required('required'),
    country: yup.string().required('required'),
    region: yup.string().required('required'),
    postcode: yup.string().required('required'),
    terms: yup.boolean().oneOf([true], 'required'),
    companyName: yup.string(),
    companyTaxNumber: yup.string(),
  };

  return yup.object().shape(schemaObj);
}

export function getForgotPasswordValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    email: yup.string().required('required').email('email'),
  };

  return yup.object().shape(schemaObj);
}

export function getResetPasswordValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    email: yup.string().required('required').email('email'),
    password: yup.string().min(8, 'passwordLength'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  };

  return yup.object().shape(schemaObj);
}

export function getMyAccountValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    password: yup
      .string()
      .transform((v, o) => (o === '' ? undefined : v))
      .notRequired()
      .min(8),
    passwordConfirmation: yup
      .string()
      .transform((v, o) => (o === '' ? undefined : v))
      .notRequired()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
    address1: yup.string().required('required'),
    address2: yup.string().notOneOf([yup.ref('address1')], 'differentAddress'),
    city: yup.string().required('required'),
    country: yup.string().required('required'),
    region: yup.string().required('required'),
    postcode: yup.string().required('required'),
    companyName: yup.string(),
    companyTaxNumber: yup.string(),
  };
  return yup.object().shape(schemaObj);
}

export function getMyAccountIsCompanyValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    password: yup
      .string()
      .transform((v, o) => (o === '' ? undefined : v))
      .notRequired()
      .min(8),
    passwordConfirmation: yup
      .string()
      .transform((v, o) => (o === '' ? undefined : v))
      .notRequired()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
    address1: yup.string().required('required'),
    address2: yup.string().notOneOf([yup.ref('address1')], 'differentAddress'),
    city: yup.string().required('required'),
    country: yup.string().required('required'),
    region: yup.string().required('required'),
    postcode: yup.string().required('required'),
    companyName: yup.string(),
    companyTaxNumber: yup.string(),
  };
  return yup.object().shape(schemaObj);
}

export function getMyAccountInstituteValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    password: yup
      .string()
      .transform((v, o) => (o === '' ? undefined : v))
      .notRequired()
      .min(8),
    passwordConfirmation: yup
      .string()
      .transform((v, o) => (o === '' ? undefined : v))
      .notRequired()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
    address1: yup.string().required('required'),
    address2: yup.string().notOneOf([yup.ref('address1')], 'differentAddress'),
    city: yup.string().required('required'),
    country: yup.string().required('required'),
    region: yup.string().required('required'),
    postcode: yup.string().required('required'),
    companyName: yup.string(),
    companyTaxNumber: yup.string(),
  };
  return yup.object().shape(schemaObj);
}

export function getInstrumentValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    client_id: yup.string(),
    brand: yup.string().required('required'),
    model: yup.string(),
    serial_number: yup.string(),
    note: yup.string(),
    age: yup.string(),
    size: yup.string(),
    color: yup.string(),
    dateOfPurchase: yup.string(),
    room: yup.string(),
    warranty: yup.string(),
  };
  return yup.object().shape(schemaObj);
}

export function getClientValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    nick: yup.string(),
    postcode: yup.string(),
    street: yup.string(),
    city: yup.string(),
    region: yup.string(),
    country: yup.string().nullable(),
    phone1: yup.string(),
    phone2: yup.string(),
    email: yup.string().email('email'),
    note: yup.string(),
  };

  return yup.object().shape(schemaObj);
}

export function getCompanyClientValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    firstName: yup.string().required('required'),
    lastName: yup.string(),
    nick: yup.string(),
    postcode: yup.string(),
    street: yup.string(),
    city: yup.string(),
    region: yup.string(),
    country: yup.string().nullable(),
    phone1: yup.string(),
    phone2: yup.string(),
    email: yup.string().email('email'),
    note: yup.string(),
  };

  return yup.object().shape(schemaObj);
}

export function getReminderDetailsSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    title: yup.string().required('required'),
    subject: yup.string().required('required'),
    text: yup.string().required('required'),
    date: yup.string(),
    repeat_in_dats: yup.number(),
  };
  return yup.object().shape(schemaObj);
}

export function getNewProjectValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    title: yup.string().required('required'),
    starting_date: yup.string(),
    description: yup.string(),
  };

  return yup.object().shape(schemaObj);
}

export function getServiceValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    title: yup.string().required('required'),
    lastDate: yup.string(),
    nextDate: yup.string(),
    description: yup.string(),
    price: yup.string(),
    duration: yup.string(),
    note: yup.string(),
    frequency: yup.string(),
    humidity: yup.string(),
    temperature: yup.string(),
  };

  return yup.object().shape(schemaObj);
}

export function getCouponValidationSchema(): yup.ObjectSchema<any> {
  const schemaObj = {
    coupon: yup.string().required('required'),
  };

  return yup.object().shape(schemaObj);
}

export function getRequestServiceValidation(): yup.ObjectSchema<any> {
  const schemaObj = {
    message: yup.string().required('required'),
    requestedBy: yup.string().required('required'),
    phone: yup.string(),
    email: yup.string().email('email'),
  };

  return yup.object().shape(schemaObj);
}

// Define Yup validation schema for the single email and recipients array
export const getSingleEmailValidation = () =>
  yup.object().shape({
    email: yup.string().email('email').required('required'),
  });
