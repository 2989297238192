export const professionChecker = () => {
  const userType = localStorage.getItem('userType');

  if (userType !== 'private') {
    return true;
  }

  const userProfessions = JSON.parse(localStorage.getItem('userProfessions') as string);
  const targetProfession = 'piano tuner';

  return (
    userProfessions.filter((profession: { id: number; title: string }) => profession.title === targetProfession)
      .length > 0
  );
};

export const professionTranslation = (translationKey: string, checkInstitute?: boolean) => {
  if (!translationKey) {
    return '';
  }

  const userType = localStorage.getItem('userType');

  if (userType === 'institute' && checkInstitute) {
    return translationKey.replaceAll('Client', 'InstituteClient').replaceAll('client', 'instituteClient');
  }

  if (userType !== 'private') {
    return translationKey;
  }

  const userProfessions = JSON.parse(localStorage.getItem('userProfessions') as string);
  const targetProfession = 'piano tuner';

  const professionExists =
    userProfessions.filter((profession: { id: number; title: string }) => profession.title === targetProfession)
      .length > 0;

  if (!professionExists) {
    return translationKey.replaceAll('Client', 'Technician').replaceAll('client', 'technician');
  }

  return translationKey;
};
