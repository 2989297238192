import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TOKEN_DATA_KEY } from '../constants/login';
import { useApi } from './useApi';
import { API_METHOD_GET } from '../constants/api';
import { MY_ACCOUNT_API } from '../constants/routes';
import { SET_LANGUAGE, SET_PROFILE } from '../constants/reduxActions';
import { IReduxStore } from '../interfaces/IGeneral';

function useProfile() {
  const { handleApi } = useApi();
  const dispatch = useDispatch();
  const { profile, language } = useSelector((store: IReduxStore) => store.defaultReducer);

  async function fetchProfile() {
    const { data } = await handleApi(API_METHOD_GET, MY_ACCOUNT_API);
    dispatch({ type: SET_PROFILE, payload: data });
    if (data) {
      //prettier-ignore
      localStorage.setItem(
        'userData',
        data.userType === 'institute'
          ? `${data.instituteName}, ${data.id}`
          : `${data.firstName} ${data.lastName}, ${data.id}`
      );
      localStorage.setItem('userProfessions', `${JSON.stringify(data.professions)}`);
      localStorage.setItem('userType', `${data.userType}`);
    }
  }

  useEffect(() => {
    if (profile) {
      dispatch({ type: SET_LANGUAGE, payload: profile.languageCode });
    }
  }, [profile?.languageCode]);

  useEffect(() => {
    if (!profile && localStorage.getItem(TOKEN_DATA_KEY)) {
      fetchProfile();
    }
  }, [profile, localStorage.getItem(TOKEN_DATA_KEY)]);

  useEffect(() => {
    if (localStorage.getItem(TOKEN_DATA_KEY)) {
      fetchProfile();
    }
  }, [language]);

  return {
    profile,
    reloadProfile: fetchProfile,
  };
}

export default useProfile;
