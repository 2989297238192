import ClientsCard from '../ClientsCard';
import ClientsForm from '../ClientsForm';
import useEditClients from '../../hooks/useEditClients';
import EditHeader from '../EditHeader';
import AdSection from '../AdSection';
import useGetTranslations from '../../hooks/useGetTranslations';
import useSetHeader from '../../hooks/useSetHeader';
import IdHeader from '../IdHeader';
import { ImageCropper } from '../ImageCropper';
import { professionTranslation } from '../../utils/roleChecker';

function EditClient(): JSX.Element {
  const {
    client,
    showLeaveWarning,
    register,
    handleSubmit,
    errors,
    selectedImage,
    handleImageChange,
    onDelete,
    selectedInstruments,
    onMatchInstrument,
    handleDeleteSelected,
    control,
    toggleEditing,
    handleDeleteProfilePicture,
    cropImage,
    handleFinishCrop,
    cropperRef,
    handleCancelCrop,
    clientType,
    handleClientType,
    profile,
  } = useEditClients();

  const { translations } = useGetTranslations();

  useSetHeader(() => {}, translations[professionTranslation('editClientTitle', true)]);

  return (
    <main>
      <EditHeader
        isEditing={true}
        toggleEditing={toggleEditing}
        handleDelete={onDelete}
        deleteTitle={
          profile?.userType !== 'institute'
            ? translations.deleteClientDescription
            : translations.deleteInstituteClientDescription
        }
        deleteButtonText={
          profile?.userType !== 'institute' ? translations.deleteClient : translations.deleteInstituteClient
        }
      />
      <AdSection topPadding>
        <IdHeader id={client?.id} />
        <ClientsCard
          isSimple={true}
          client={client}
          selectedImage={selectedImage}
          handleImageChange={handleImageChange}
          isEditing={true}
          handleProfileImageDelete={handleDeleteProfilePicture}
        />
        <>
          {cropImage !== '' && (
            <ImageCropper
              cropperRef={cropperRef}
              cropImage={cropImage}
              handleFinishCrop={handleFinishCrop}
              cropText={translations.crop}
              cancelText={translations.cancel}
              handleCancel={handleCancelCrop}
            />
          )}
        </>
        <ClientsForm
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          selectedInstruments={selectedInstruments}
          onMatchInstrument={onMatchInstrument}
          onDeleteClick={handleDeleteSelected}
          control={control}
          showLeaveWarning={showLeaveWarning}
          onTypeChange={handleClientType}
          clientType={clientType}
        />
      </AdSection>
    </main>
  );
}

export default EditClient;
