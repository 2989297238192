import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import HomeSearchHeader from '../HomeSearchHeader';
import NewHeader from '../NewHeader';
import MyInstrumentsFilter from '../MyInstrumentsFilter';
import InstrumentCard from '../InstrumentCard';
import useInstruments from '../../hooks/useInstruments';
import FilterElement from '../FilterElement';
import AdSection from '../AdSection';
import { NEW_INSTRUMENT } from '../../constants/routes';
import { IReduxStore } from '../../interfaces/IGeneral';
import GlobalSearchElements from '../GlobalSearchElements';
import useGetTranslations from '../../hooks/useGetTranslations';
import { filterDuplicateNames } from '../../helpers/baseHelper';
import useMobile from '../../hooks/useMobile';
import useMyInstrumentsFilter from '../../hooks/useMyInstrumentsFilter';
import { paddingForInfiniteScroll } from '../../constants/infiniteScrollStyle';
import { professionChecker } from '../../utils/roleChecker';
import { useMemo } from 'react';
import useSetHeader from '../../hooks/useSetHeader';

function HomePage(): JSX.Element {
  const { translations } = useGetTranslations();
  //prettier-ignore
  const { instruments, globalSearch, instrumnetsClients, actualFilter, profile } = useSelector(
    (store: IReduxStore) => store.defaultReducer
  );
  const { filteredInstruments, total, nextPage } = useInstruments();
  const { isMobile } = useMobile();
  const { toggle, myInstruments, handleToggle } = useMyInstrumentsFilter();
  const isTuner = useMemo(() => professionChecker(), []);

  setTimeout(() => {
    return <></>;
  }, 500);

  const userData = localStorage.getItem('userData');
  const title = userData ? userData.split(',')[0] : 'Piano Service App';

  useSetHeader(() => {}, title, undefined, false, true);

  //prettier-ignore
  return (
    <main>
      <HomeSearchHeader
        hideFilters={!isTuner}
        hideSort={!isTuner}
        sortTitle={{
          ascending: translations.sortInstrumentsAscending,
          descending: translations.sortInstrumentsDescending,
        }}
        sortField="brand"
        parentPage="intrumentList"
        filters={
          <>
            { profile?.userType !== 'institute' && <MyInstrumentsFilter toggle={toggle} handleToggle={handleToggle} /> }
            <FilterElement name={translations.byCategory} field="category" isSelect isNumber={false} />
            <FilterElement
              name={translations.byInstrument}
              data={
                toggle
                  ? filterDuplicateNames(
                    myInstruments.map((piano) => ({ id: piano.id, name: piano.brand }))
                  )
                  : filterDuplicateNames(
                    instruments.map((piano) => ({ id: piano.id, name: piano.brand }))
                  )
              }
              field="instruments"
              isNumber={false}
            />
            {!toggle && (
              <>
                <FilterElement
                  name={ profile?.userType !== 'institute' ? translations.byClients : translations.byInstituteClient}
                  field="clients"
                  data={instrumnetsClients.map((cli) => ({
                    id: cli.id,
                    name: `${cli.name}, ID: ${cli.id}`,
                  }))}
                  isNumber={true}
                />
                <FilterElement
                  name={translations.byCity}
                  field="city"
                  data={filterDuplicateNames(instrumnetsClients.map((cli) => ({ id: cli.id, name: cli.city })))}
                  isNumber={false}
                />
                <FilterElement
                  name={translations.byRegion}
                  field="region"
                  data={filterDuplicateNames(instrumnetsClients.map((cli) => ({ id: cli.id, name: cli.region })))}
                  isNumber={false}
                />
                <FilterElement
                  name={translations.byPostcode}
                  field="postcode"
                  data={filterDuplicateNames(instrumnetsClients.map((cli) => ({ id: cli.id, name: cli.postcode })))}
                  isNumber={false}
                />
              </>
            )}
          </>
        }
      />
      {Object.keys(globalSearch).length > 0 ? (
        <GlobalSearchElements />
      ) : (
        <>
          {
            Object.keys(actualFilter).length === 0 &&
              <NewHeader
                to={NEW_INSTRUMENT}
                leftTitle={`${translations.homePageLeftTitle} (${total})`}
                rightTitle={translations.homePageRightTitle}
                type="instrument"
              />
          }
          <AdSection length={total} noPadding>
            {isMobile ? (
              <InfiniteScroll
                dataLength={filteredInstruments.length}
                next={nextPage}
                hasMore={true}
                loader={<></>}
                style={paddingForInfiniteScroll}
              >
                {filteredInstruments.map((instrument) => (
                  <InstrumentCard key={instrument.id} isSimple={false} {...instrument} room={instrument.room} serialNumber={instrument.serialNumber} />
                ))}
              </InfiniteScroll>
            ) : (
              <>
                {filteredInstruments.map((instrument) => (
                  <InstrumentCard key={instrument.id} isSimple={false} {...instrument} room={instrument.room} serialNumber={instrument.serialNumber} />
                ))}
              </>
            )}
          </AdSection>
        </>
      )}
    </main>
  );
}

export default HomePage;
