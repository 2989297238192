import InstrumentForm from '../InstrumentForm';
import InstrumentCard from '../InstrumentCard';
import useInstrument from '../../hooks/useInstrument';
import EditHeader from '../EditHeader';
import useGetTranslations from '../../hooks/useGetTranslations';
import AdSection from '../AdSection';
import IdHeader from '../IdHeader';
import useSetHeader from '../../hooks/useSetHeader';
import { ImageCropper } from '../ImageCropper';

function EditInstrument(): JSX.Element {
  const {
    handleImageChange,
    uploadImage,
    instrument,
    register,
    handleSubmitForm,
    selectedType,
    setSelectedType,
    errors,
    onAttachmentChange,
    onPictureChange,
    handleSuggestionChange,
    selectedClient,
    handleDeleteSelected,
    handleDelete,
    attachmentNr,
    pictureNr,
    canLeave,
    handleDeleteProfileImage,
    cropImage,
    handleFinishCrop,
    cropperRef,
    handleCancelCrop,
    cropUploadImage,
    handleCancelUploadCrop,
    handleFinishUploadCrop,
    uploadCropperRef,
    handleBrandChange,
    handleNewBrandNameChange,
    newBrandName,
    brand,
  } = useInstrument();
  const { translations } = useGetTranslations();
  useSetHeader(() => {}, translations.editInstrumentTitle);

  return (
    <main>
      <EditHeader
        isEditing={true}
        handleDelete={handleDelete}
        deleteTitle={translations.deleteInstrumentDescription}
        deleteButtonText={translations.deleteInstrument}
      />

      <AdSection topPadding>
        <>
          {instrument?.id && <IdHeader isInstrument id={instrument.id} />}
          <InstrumentCard
            {...instrument}
            isSimple
            handleImageChange={handleImageChange}
            handleProfileImageDelete={handleDeleteProfileImage}
            uploadImage={uploadImage}
          />
          {cropImage !== '' && (
            <ImageCropper
              cropperRef={cropperRef}
              cropImage={cropImage}
              handleFinishCrop={handleFinishCrop}
              cropText={translations.crop}
              cancelText={translations.cancel}
              handleCancel={handleCancelCrop}
            />
          )}

          <InstrumentForm
            register={register}
            handleSubmitForm={handleSubmitForm}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            errors={errors}
            onAttachmentChange={onAttachmentChange}
            onPictureChange={onPictureChange}
            instrument={instrument}
            onSuggestionChange={handleSuggestionChange}
            selectedClient={selectedClient}
            onDeleteClick={handleDeleteSelected}
            pictureNr={pictureNr}
            attachmentNr={attachmentNr}
            canLeave={canLeave}
            isEditing
            uploadCropperRef={uploadCropperRef}
            uploadCropImage={cropUploadImage}
            handleUploadCrop={handleFinishUploadCrop}
            handleCancelUploadCrop={handleCancelUploadCrop}
            handleNewBrandNameChange={handleNewBrandNameChange}
            onMatch={handleBrandChange}
            brand={brand}
            newBrandName={newBrandName}
          />
        </>
      </AdSection>
    </main>
  );
}

export default EditInstrument;
