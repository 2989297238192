import { Route, Switch } from 'react-router-dom';

import {
  HOME_PAGE,
  LOGIN_PAGE,
  FORGOT_PASSWORD,
  REGISTRATION,
  PROFILE,
  MY_ACCOUNT,
  MY_SUBSCRIPTION,
  PLANS,
  CURRENCY_LANGUAGE,
  INSTRUMENT_DETAILS,
  NEW_INSTRUMENT,
  EDIT_INSTRUMENT,
  ATTACHMENTS_PAGE,
  CLIENTS_PAGE,
  NEW_CLIENT_PAGE,
  REMINDERS_PAGE,
  INSTRUMENT_SERVICES,
  REMINDER_DETAILS,
  LOG_OUT,
  SERVICES_PAGE,
  PROJECTS_PAGE,
  NEW_PROJECT_PAGE,
  NEW_SERVICE_PAGE,
  SERVICE_DETAIL,
  CLIENTS_SERVICES,
  NEW_REMINDER_PAGE,
  RESET_PASSWORD,
  NEW_SERVICE_PAGE_WITH_PROJECT,
  EDIT_CLIENT,
  CLIENT_DETAILS,
  EDIT_SERVICE,
  EDIT_PROJECT,
  PROJECT_DETAIL,
  EDIT_REMINDER,
  EDIT_ATTACHMENTS_PAGE,
  GUEST_INSTRUMENT_DETAIL,
  LOGIN_PERMISSIONS,
  MY_PERMISSIONS,
} from '../constants/routes';
import LoginPage from './pages/Login';
import ForgotPasswordPage from './pages/ForgotPassword';
import Registration from './pages/Registration';
import PrivateRoute from './PrivateRoute';
import ProfileSettings from './pages/ProfileSettings';
import HomePage from './pages/HomePage';
import MyAccount from './pages/MyAccount';
import MySubscription from './pages/MySubscription';
import Plans from './pages/Plans';
import Currency from './pages/Currency';
import InstrumentDetails from './pages/InstrumentDetails';
import NewInstrument from './pages/NewInstrument';
import EditInstrument from './pages/EditInstrument';
import Attachments from './pages/Attachments';
import EditAttachments from './pages/EditAttachments';
import ClientsPage from './pages/ClientsPage';
import NewClient from './pages/NewClient';
import EditClient from './pages/EditClient';
import ClientDetails from './pages/ClientDetails';
import Reminders from './pages/Reminders';
import InstrumentServices from './pages/InstrumentsServices';
import ReminderDetails from './pages/ReminderDetails';
import EditReminder from './pages/EditReminder';
import Logout from './pages/Logout';
import Services from './pages/Services';
import Projects from './pages/Projects';
import NewProject from './pages/NewProject';
import NewService from './pages/NewService';
import ProjectDetail from './pages/ProjectDetail';
import EditProject from './pages/EditProject';
import ServiceDetail from './pages/ServiceDetail';
import EditService from './pages/EditService';
import ClientsServices from './pages/ClientsServices';
import NewReminderPage from './pages/NewReminderPage';
import ResetPassword from './pages/ResetPassword';
import { GuestInstrumentDetails } from './pages/GuestIntrumentDetails';
import { LoginPermissions } from './pages/LoginPermissions';
import { MyPermissions } from './pages/MyPermissions';

function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path={LOGIN_PAGE} component={LoginPage} />
      <Route path={FORGOT_PASSWORD} component={ForgotPasswordPage} />
      <Route path={REGISTRATION} component={Registration} />
      <Route path={RESET_PASSWORD} component={ResetPassword} />
      <Route path={GUEST_INSTRUMENT_DETAIL} component={GuestInstrumentDetails} />

      <PrivateRoute exact={true} element={ProfileSettings} path={PROFILE} />
      <PrivateRoute exact={true} element={MyAccount} path={MY_ACCOUNT} />
      <PrivateRoute exact={true} element={MySubscription} path={MY_SUBSCRIPTION} />
      <PrivateRoute exact={true} element={Currency} path={CURRENCY_LANGUAGE} />
      <PrivateRoute exact={true} element={LoginPermissions} path={LOGIN_PERMISSIONS} />
      <PrivateRoute exact={true} element={MyPermissions} path={MY_PERMISSIONS} />
      <PrivateRoute exact={true} element={Plans} path={PLANS} />
      <PrivateRoute exact={true} element={Logout} path={LOG_OUT} />

      <PrivateRoute exact={true} element={HomePage} path={HOME_PAGE} />
      <PrivateRoute exact={true} element={ClientsPage} path={CLIENTS_PAGE} />
      <PrivateRoute element={ClientsServices} path={CLIENTS_SERVICES} />
      <PrivateRoute element={InstrumentServices} path={INSTRUMENT_SERVICES} />
      <PrivateRoute exact={true} element={Attachments} path={ATTACHMENTS_PAGE} />
      <PrivateRoute exact={true} element={EditAttachments} path={EDIT_ATTACHMENTS_PAGE} />
      <PrivateRoute exact={true} element={Reminders} path={REMINDERS_PAGE} />
      <PrivateRoute exact={true} element={Services} path={SERVICES_PAGE} />
      <PrivateRoute exact={true} element={Projects} path={PROJECTS_PAGE} />

      <PrivateRoute exact={true} element={NewInstrument} path={NEW_INSTRUMENT} />
      <PrivateRoute exact={true} element={NewInstrument} path={NEW_INSTRUMENT + '?clientId=:id'} />
      <PrivateRoute exact={true} element={InstrumentDetails} path={INSTRUMENT_DETAILS} />
      <PrivateRoute exact={true} element={EditInstrument} path={EDIT_INSTRUMENT} />

      <PrivateRoute exact={true} element={NewClient} path={NEW_CLIENT_PAGE} />
      <PrivateRoute exact={true} element={NewClient} path={NEW_CLIENT_PAGE + '?instrumentId=:id'} />
      <PrivateRoute exact={true} element={ClientDetails} path={CLIENT_DETAILS} />
      <PrivateRoute exact={true} element={EditClient} path={EDIT_CLIENT} />

      <PrivateRoute exact={true} element={NewService} path={NEW_SERVICE_PAGE} />
      <PrivateRoute exact={true} element={NewService} path={NEW_SERVICE_PAGE_WITH_PROJECT} />
      <PrivateRoute exact={true} element={ServiceDetail} path={SERVICE_DETAIL} />
      <PrivateRoute exact={true} element={EditService} path={EDIT_SERVICE} />

      <PrivateRoute exact={true} element={NewProject} path={NEW_PROJECT_PAGE} />
      <PrivateRoute exact={true} element={ProjectDetail} path={PROJECT_DETAIL} />
      <PrivateRoute exact={true} element={EditProject} path={EDIT_PROJECT} />

      <PrivateRoute element={NewReminderPage} path={NEW_REMINDER_PAGE} />
      <PrivateRoute exact={true} element={ReminderDetails} path={REMINDER_DETAILS} />
      <PrivateRoute exact={true} element={EditReminder} path={EDIT_REMINDER} />
    </Switch>
  );
}

export default Routes;
