import React, { createRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Resizer from 'react-image-file-resizer';

import { CLIENTS_PAGE, CLIENT_DETAILS, GET_CLIENTS } from '../constants/routes';
import { ADD_CLIENT, SET_IS_LOADING, SET_SHOULD_LEAVE } from '../constants/reduxActions';
import { useApi } from './useApi';
import { API_METHOD_POST } from '../constants/api';
import useYupValidationResolver from './useYupValidationResolver';
import { getClientValidationSchema, getCompanyClientValidationSchema } from '../helpers/validationSchemaHelper';
import { getBase64, toCamelCase } from '../helpers/baseHelper';
import { IUseNewClient } from '../interfaces/components/Hooks';
import { ISuggestion } from '../interfaces/components/SuggestionSearch';
import { IInstrument } from '../interfaces/components/Instrument';
import { IReduxStore } from '../interfaces/IGeneral';
import useGetTranslations from './useGetTranslations';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { useHistory } from 'react-router';
import useGeneral from './useGeneral';
import { ReactCropperElement } from 'react-cropper';
import { nanoid } from 'nanoid';

function useNewClient(): IUseNewClient {
  const { instruments, profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const [selectedImage, setSelectedImage] = useState<{ file: string; name: string }>();
  const [selectedInstruments, setSelectedInstruments] = useState<Array<IInstrument>>([]);
  const [showLeaveWarning, setShowLeaveWarning] = useState(true);
  const [cropImage, setCropImage] = useState<string>('');
  //prettier-ignore
  const [clientType, setClientType] = useState<'private' | 'company'>(
    profile?.userType === 'institute' ? 'company' : 'private'
  );
  const cropperRef = createRef<ReactCropperElement>();
  //prettier-ignore
  const resolver = useYupValidationResolver(
    clientType !== 'company' ? getClientValidationSchema() : getCompanyClientValidationSchema()
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleRedirect } = useGeneral();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({ resolver });
  const { handleApi } = useApi();
  const { translations } = useGetTranslations();

  const onConvertReady = (fileString: string | ArrayBuffer | null, name: string) => {
    setSelectedImage({ file: fileString as string, name });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      // getBase64(file, onConvertReady);
      getBase64(file, handleCropImage);
    }
  };

  const handleCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropImage(fileString);
    }
  };

  const handleCancelCrop = () => {
    setCropImage('');
  };

  const handleFinishCrop = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const croppedImageDataURL = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      const byteString = atob(croppedImageDataURL.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/png' });

      // Create a File object from the Blob
      const file = new File([blob], 'cropped_image.png', { type: 'image/png' });

      const resized = await resizeFile(file);
      onConvertReady(resized as any, `${nanoid()}}.png`);
      setCropImage('');
    }
  };

  /*prettier-ignore*/
  const resizeFile = (file: File): Promise<File> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1020,
        1020,
        'JPEG',
        200,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'base64'
      );
    });

  const onMatchInstrument = (dat: ISuggestion) => {
    if (dat && !selectedInstruments.find((instr) => instr.id === dat.id)) {
      const intst = instruments.find((inst) => inst.id === dat.id);
      if (intst) setSelectedInstruments([...selectedInstruments, intst]);
    }
  };

  const setInstrument = (id: number) => {
    if (id && !selectedInstruments.find((instr) => instr.id === id)) {
      const intst = instruments.find((inst) => inst.id === id);
      if (intst) setSelectedInstruments([...selectedInstruments, intst]);
    }
  };

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   if (files && files.length > 0) {
  //     const file = files[0];
  //     getBase64(file, onConvertReady);
  //   }
  // };

  async function onSubmit(formData: any) {
    let form = {
      ...formData,
      instrumentIds: selectedInstruments.map((instr) => instr.id),
      country: getValues('country') === null || getValues('country') === undefined ? '' : getValues('country'),
      type: clientType,
      lastName: clientType === 'company' ? '' : formData.lastName,
    };
    if (selectedImage?.name)
      form = {
        ...form,
        thumbnail: selectedImage.file,
        thumbnail_name: selectedImage.name,
      };
    const { data, code, message } = await handleApi(API_METHOD_POST, GET_CLIENTS, form);
    if (code === 200) {
      dispatch({ type: ADD_CLIENT, payload: data });
      dispatch({ type: SET_SHOULD_LEAVE, payload: true });
      dispatch({ type: SET_IS_LOADING, payload: true });
      setShowLeaveWarning(false);
      history.goBack();
      history.replace(CLIENTS_PAGE);
      setTimeout(() => {
        handleRedirect(CLIENT_DETAILS.replace(':id', String(data.id)));
      }, 100);
      dispatch({ type: SET_IS_LOADING, payload: false });
      toast.success(translations.savedSuccessfully);
      return;
    }
    if (message === 'no_active_subscription') {
      toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
      return;
    }
    toast.error(translations[toCamelCase(message)]);
    if (data) {
      Object.values(data).map((err: any) => {
        toast.error(err);
      });
    }
  }

  const handleDeleteClick = (id: number) => {
    setSelectedInstruments([...selectedInstruments.filter((instrument) => instrument.id !== id)]);
  };

  const handleProfileImageDelete = () => {
    setSelectedImage(undefined);
  };

  const handleClientType = (newType: string) => {
    setClientType(newType as 'private' | 'company');
  };

  return {
    selectedImage: selectedImage?.file,
    handleImageChange,
    register,
    errors,
    handleSubmit: handleSubmit(onSubmit),
    selectedInstruments,
    onMatchInstrument,
    handleDeleteClick,
    control,
    showLeaveWarning,
    setInstrument,
    handleProfileImageDelete,
    cropperRef,
    cropImage,
    handleFinishCrop,
    handleCancelCrop,
    clientType,
    handleClientType,
  };
}

export default useNewClient;
