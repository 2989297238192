import SwitcherComponent from '../SwitcherComponent';
import useInstrumentServices from '../../hooks/useInstrumentServices';
import ReminderDetailCard from '../ReminderDetailCard';
import InstrumentCard from '../InstrumentCard';
import ProjectCard from '../ProjectCard';
import ServiceCard from '../ServiceCard';
import AdSection from '../AdSection';
import useSetHeader from '../../hooks/useSetHeader';
import useGetTranslation from '../../hooks/useGetTranslations';
import {
  PROJECT_DETAIL,
  SERVICE_DETAIL,
  SERVICES_PAGE,
  REMINDERS_PAGE,
  PROJECTS_PAGE,
  NEW_CLIENT_PAGE,
  CLIENT_DETAILS,
  ATTACHMENTS_PAGE,
  INSTRUMENT_DETAILS,
} from '../../constants/routes';
import IdHeader from '../IdHeader';
import InstrumentServicesHeader from '../InstrumentServicesHeader';
import { ISimpleProject } from '../../interfaces/components/Project';
import { ISimpleService } from '../../interfaces/components/Service';
import useGeneral from '../../hooks/useGeneral';
import { ServiceCardTypes } from '../../constants/ServiceCard';
import { ProjectCardTypes } from '../../constants/projects';
import FilterElement from '../FilterElement';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMobile from '../../hooks/useMobile';
import { MenuBar } from '../MenuBar';
import { BsCameraFill, BsFillPersonFill } from 'react-icons/bs';
import {
  ADD_INSTRUMENT_SERVICES_FILTER,
  RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER,
  RESET_INSTRUMENT_SERVICES_FILTER,
  SET_ATTACHMENTS_PAGE_SWITCHER,
  SET_INSTRUMENT_SERVICES_FILTER,
} from '../../constants/reduxActions';
import { ImAttachment } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { MdPiano } from 'react-icons/md';
import { toast } from 'react-toastify';
import LimitExceededNotification, { limitExceededOptions } from '../LimitExceededNotification';
import useMySubscriptionPlan from '../../hooks/useMySubscriptionPlan';
import { IReduxStore } from '../../interfaces/IGeneral';
import { InstrumentServiceIconInfo } from '../info/InstrumentServiceInfo';
import { filterDuplicateNames } from '../../helpers/baseHelper';
import { ISuggestion } from '../../interfaces/components/SuggestionSearch';
import { useMemo } from 'react';
import { professionChecker } from '../../utils/roleChecker';

function InstrumentServices(): JSX.Element {
  const {
    switcherPage,
    toggleSwitcherPage,
    projects,
    instrument,
    onReminderClick,
    reminders,
    filteredServices,
    nextPage,
    total,
    allServices,
  } = useInstrumentServices();
  const { actualInstrumentServicesFilter } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { isMobile } = useMobile();
  const { handleRedirect } = useGeneral();
  const { translations } = useGetTranslation();
  const dispatch = useDispatch();
  const { canCreateNewClient } = useMySubscriptionPlan();
  const secondTitle =
    switcherPage === 1
      ? { name: translations.instrumentProjectsTitle, place: PROJECTS_PAGE }
      : { name: translations.instrumentRemindersTitle, place: REMINDERS_PAGE };
  const title = switcherPage === 0 ? { name: translations.instrumentServicesTitle, place: SERVICES_PAGE } : secondTitle;

  //prettier-ignore
  useSetHeader(
    () => {
      handleRedirect(title.place);
    },
    title.name,
    undefined,
    true
  );

  const isTuner = useMemo(() => professionChecker(), []);

  //prettier-ignore
  return (
    <main>
      <InstrumentServicesHeader
        hideFilters={!isTuner}
        hideSort={!isTuner}
        switcher={switcherPage}
        actualFilter={actualInstrumentServicesFilter}
        filters={
          <>
            <FilterElement
              name={translations.byTuner}
              field="tuners"
              data={filterDuplicateNames(
                allServices
                  .map((service: ISimpleService) => {
                    if (service.tuner !== null) {
                      return service.tuner;
                    }
                  })
                  .filter((tuner): tuner is ISuggestion => tuner !== undefined)
              )}
              isNumber={true}
              addFilterRedux={ADD_INSTRUMENT_SERVICES_FILTER}
              setFilterRedux={SET_INSTRUMENT_SERVICES_FILTER}
              resetActualFilterRedux={RESET_INSTRUMENT_SERVICES_ACTUAL_FILTER}
              resetFilterRedux={RESET_INSTRUMENT_SERVICES_FILTER}
            />
          </>
        }
      />
      <AdSection length={total} topPadding>
        <IdHeader isInstrument={true} id={instrument?.id} />
        {<InstrumentCard isSimple={true} {...instrument} noMarginBottom />}
        <MenuBar
          items={[
            {
              icon: <MdPiano />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                handleRedirect(INSTRUMENT_DETAILS.replace(':id', instrument.id.toString()));
              },
            },
            {
              icon: <BsFillPersonFill />,
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                if (!instrument.client) {
                  if (!canCreateNewClient()) {
                    toast.error(<LimitExceededNotification />, limitExceededOptions);
                    return;
                  }
                  handleRedirect(NEW_CLIENT_PAGE + `?instrumentId=${instrument.id}`);
                  return;
                } else {
                  handleRedirect(CLIENT_DETAILS.replace(':id', instrument.client.id.toString()));
                }
              },
            },
            {
              icon: <BsCameraFill />,
              //prettier-ignore
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'image' });
                handleRedirect(
                  ATTACHMENTS_PAGE.replace(':id', instrument.id.toString()).replace(':type', 'instrument')
                );
              },
            },
            {
              icon: <ImAttachment />,
              //prettier-ignore
              onClick: () => {
                if (!instrument || !instrument.id) {
                  return;
                }
                dispatch({ type: SET_ATTACHMENTS_PAGE_SWITCHER, payload: 'file' });
                handleRedirect(
                  ATTACHMENTS_PAGE.replace(':id', instrument.id.toString()).replace(':type', 'instrument')
                );
              },
            },
          ]}
        />

        <SwitcherComponent
          switcherPage={switcherPage}
          toggleSwitcherPage={toggleSwitcherPage}
          titles={[translations.servicesTitle, translations.projectsTitle, translations.remindersLeftTitle]}
          elements={[
            <>
              <InstrumentServiceIconInfo type={switcherPage} />

              {isMobile ? (
                <InfiniteScroll dataLength={filteredServices.length} next={nextPage} hasMore={true} loader={<></>}>
                  {filteredServices?.slice(0).map((service: ISimpleService) => (
                    <ServiceCard
                      key={service.id}
                      service={service}
                      type={ServiceCardTypes.INSTRUMENT_SERVICE}
                      onClick={() => {
                        handleRedirect(SERVICE_DETAIL.replace(':id', service.id.toString()));
                      }}
                      showProjectIcon={service?.projectId !== undefined && service.projectId !== null}
                      disableActivate
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                filteredServices?.slice(0).map((service: ISimpleService) => (
                  <ServiceCard
                    key={service.id}
                    service={service}
                    type={ServiceCardTypes.INSTRUMENT_SERVICE}
                    onClick={() => {
                      handleRedirect(SERVICE_DETAIL.replace(':id', service.id.toString()));
                    }}
                    showProjectIcon={service?.projectId !== undefined && service.projectId !== null}
                    disableActivate
                  />
                ))
              )}
            </>,
            <>
              <InstrumentServiceIconInfo type={switcherPage} />
              {isMobile ? (
                <InfiniteScroll dataLength={projects.length} next={nextPage} hasMore={true} loader={<></>}>
                  {projects?.map((project: ISimpleProject) => (
                    <ProjectCard
                      project={project}
                      key={project.id}
                      type={ProjectCardTypes.INSTRUMENT_PROJECT}
                      onClick={() => {
                        handleRedirect(PROJECT_DETAIL.replace(':id', project.id.toString()));
                      }}
                      showInstrumentData={false}

                    />
                  ))}
                </InfiniteScroll>
              ) : (
                projects?.map((project: ISimpleProject) => (
                  <ProjectCard
                    project={project}
                    key={project.id}
                    type={ProjectCardTypes.INSTRUMENT_PROJECT}
                    onClick={() => {
                      handleRedirect(PROJECT_DETAIL.replace(':id', project.id.toString()));
                    }}
                    showInstrumentData={false}
                  />
                ))
              )}
            </>,
            <>
              {isMobile ? (
                <InfiniteScroll dataLength={reminders.length} next={nextPage} hasMore={true} loader={<></>}>
                  {reminders?.map((reminder) => (
                    <ReminderDetailCard key={reminder.id} reminder={{ ...reminder }} onClick={onReminderClick} />
                  ))}
                </InfiniteScroll>
              ) : (
                reminders?.map((reminder) => (
                  <ReminderDetailCard key={reminder.id} reminder={{ ...reminder }} onClick={onReminderClick} />
                ))
              )}
            </>,
          ]}
        />
      </AdSection>
    </main>
  );
}

export default InstrumentServices;
