import { AiFillInfoCircle } from 'react-icons/ai';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import style from '../../assets/scss/components/info/GeneralInfoBox.module.scss';

interface GeneralInfoBoxProps {
  text: string;
}

export const GeneralInfoBox = ({ text }: GeneralInfoBoxProps) => {
  const { infoContainer, infoDescription, infoImage } = convertKeysToCamelCase(style);

  return (
    <div className={infoContainer}>
      <AiFillInfoCircle className={infoImage} />
      <p className={infoDescription}>{text}</p>
    </div>
  );
};
