import { GiWaterDrop, GiSoundWaves } from 'react-icons/gi';
import { RiTempColdLine } from 'react-icons/ri';

import style from '../assets/scss/components/LatestInfo.module.scss';
import Title from './Title';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { ILatestInfo } from '../interfaces/components/Instrument';
import useGetTranslations from '../hooks/useGetTranslations';
import useProfile from '../hooks/useProfile';

function LatestInfo({
  frequency,
  humidity,
  isEditing,
  needsWhiteInputs,
  register,
  temperature,
}: ILatestInfo): JSX.Element {
  const { row, icon, attribute, text, whiteInput } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const { profile } = useProfile();

  return (
    <div>
      <Title title={translations.latestInfo} />
      <div className={row}>
        <GiSoundWaves className={icon} />
        {isEditing ? (
          <input
            type="text"
            name="frequency"
            ref={register}
            className={`primary ${attribute} ${needsWhiteInputs ? whiteInput : ''}`}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={`bubble ${attribute} ${needsWhiteInputs ? whiteInput : ''}`}>{frequency}</p>
        )}
        <p className={text}>Hz</p>
      </div>
      <div className={row}>
        <GiWaterDrop className={icon} />
        {isEditing ? (
          <input
            type="text"
            name="humidity"
            ref={register}
            className={`primary ${attribute} ${needsWhiteInputs ? whiteInput : ''}`}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={`bubble ${attribute} ${needsWhiteInputs ? whiteInput : ''}`}>{humidity}</p>
        )}
        <p className={text}>%</p>
      </div>
      <div className={row}>
        <RiTempColdLine className={icon} />
        {isEditing ? (
          <input
            type="text"
            name="temperature"
            ref={register}
            className={`primary ${attribute} ${needsWhiteInputs ? whiteInput : ''}`}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        ) : (
          <p className={`bubble ${attribute} ${needsWhiteInputs ? whiteInput : ''}`}>{temperature}</p>
        )}
        <p className={text}>{profile?.temperatureUnit}</p>
      </div>
    </div>
  );
}

export default LatestInfo;
