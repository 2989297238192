import { useSelector } from 'react-redux';
import useProfileCard from '../../hooks/useProfileCard';
import ProfileCard from '../ProfileCard';
import { IReduxStore } from '../../interfaces/IGeneral';
import AdSection from '../AdSection';
import Title from '../Title';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import useGetTranslations from '../../hooks/useGetTranslations';
import style from '../../assets/scss/pages/LoginPermissions.module.scss';
import { GeneralInfoBox } from '../info/GeneralInfoBox';
import { useForm } from 'react-hook-form';
import useYupValidationResolver from '../../hooks/useYupValidationResolver';
import { getSingleEmailValidation } from '../../helpers/validationSchemaHelper';
import { AddLoginPermissionForm, Permission, Recipient } from '../../interfaces/components/LoginPermissions';
import { BsTrash } from 'react-icons/bs';
import AlertModal from '../AlertModal';
import { useEffect, useState } from 'react';
import useSetHeader from '../../hooks/useSetHeader';
import {
  INSTITUTE_DELETE_INVITE,
  INSTITUTE_DELETE_REMINDER_INVITE,
  INSTITUTE_GET_INVITE,
  INSTITUTE_GET_REMINDER_INVITE,
  INSTITUTE_SEND_INVITE,
  INSTITUTE_SEND_REMINDER_INVITE,
  PROFILE,
} from '../../constants/routes';
import useGeneral from '../../hooks/useGeneral';
import { useApi } from '../../hooks/useApi';
import { API_METHOD_DELETE, API_METHOD_GET, API_METHOD_POST } from '../../constants/api';
import { toast } from 'react-toastify';

export const LoginPermissions = () => {
  const { uploadImage } = useProfileCard();
  const { profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { titleMargin, whiteInput, row, inputError, emailRow, emailSection, icon } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [reminderInvites, setReminderInvites] = useState<Recipient[]>([]);
  const { handleApi } = useApi();
  const { handleRedirect } = useGeneral();
  const [isOpen, setOpen] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState<Permission | null>(null);

  const fetchPermissions = async () => {
    const { data } = await handleApi(API_METHOD_GET, INSTITUTE_GET_INVITE);
    setPermissions(data);
  };

  const fetchReminderInvites = async () => {
    const { data } = await handleApi(API_METHOD_GET, INSTITUTE_GET_REMINDER_INVITE);
    setReminderInvites(data);
  };

  useEffect(() => {
    if (profile?.userType !== 'institute') {
      handleRedirect(PROFILE);
      return;
    }
    fetchPermissions();
    fetchReminderInvites();
  }, [profile]);

  const singleEmailResolver = useYupValidationResolver(getSingleEmailValidation());
  const recipientsResolver = useYupValidationResolver(getSingleEmailValidation());

  const {
    register: registerSingleEmail,
    handleSubmit: handleSingleEmailSubmit,
    formState: { errors: singleEmailErrors },
    reset: resetSingleEmail,
  } = useForm<AddLoginPermissionForm>({
    resolver: singleEmailResolver,
  });

  const {
    register: registerRecipients,
    handleSubmit: handleRecipientsSubmit,
    formState: { errors: recipientsErrors },
    reset: resetRecipients,
  } = useForm<AddLoginPermissionForm>({
    resolver: recipientsResolver,
  });

  const submitSingleEmailForm = async (data: AddLoginPermissionForm) => {
    const { code } = await handleApi(API_METHOD_POST, INSTITUTE_SEND_INVITE, { ...data });
    if (code === 200) {
      fetchPermissions();
      resetSingleEmail();
      toast.success(translations.loginPermissionsSuccess);
    }

    return;
  };

  const submitRecipientsForm = async (data: AddLoginPermissionForm) => {
    const { code } = await handleApi(API_METHOD_POST, INSTITUTE_SEND_REMINDER_INVITE, { ...data });
    if (code === 200) {
      fetchReminderInvites();
      resetRecipients();
      toast.success(translations.loginPermissionsReminderSuccess);
    }

    return;
  };

  const handleDeletePermission = async () => {
    if (emailToDelete) {
      await handleApi(API_METHOD_DELETE, INSTITUTE_DELETE_INVITE.replace(':id', emailToDelete.id.toString()));
      setOpen(false);
      setEmailToDelete(null);
      fetchPermissions();
      return;
    }
  };

  const handleDeleteReminderInvite = async (id: number) => {
    await handleApi(API_METHOD_DELETE, INSTITUTE_DELETE_REMINDER_INVITE.replace(':id', id.toString()));
    setOpen(false);
    return;
  };

  const onClose = () => {
    setOpen(false);
    setEmailToDelete(null);
  };

  useSetHeader(() => {
    handleRedirect(PROFILE);
  }, translations.instituteLoginPermissions);

  return (
    <AdSection>
      <ProfileCard uploadImage={uploadImage} clientId={profile?.id} />

      {/* Single Email Form */}
      <div className={titleMargin}>
        <Title title={translations.loginPermissionsLoginActivationTitle} />
      </div>
      <GeneralInfoBox text={translations.loginPermissionsLoginActivationDescription} />
      <form onSubmit={handleSingleEmailSubmit(submitSingleEmailForm)}>
        <div className={row}>
          <input
            name="email"
            id="email"
            className={`primary ${whiteInput}`}
            ref={registerSingleEmail}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
          {/* Display validation error if there's an issue with the email */}
          <p className={inputError}>{singleEmailErrors.email?.message ? translations.errorOnEmailField : null}</p>
        </div>

        <button className="blue" type="submit" disabled={false}>
          {translations.loginPermissionsSend}
        </button>
      </form>

      {/* Cancel permissions section */}
      <div className={titleMargin}>
        <Title title={translations.loginPermissionsCancelPermissionsTitle} />
      </div>
      <GeneralInfoBox text={translations.loginPermissionsCancelPermissionsDescription} />

      <div className={emailSection}>
        {permissions.map((p) => (
          <div className={emailRow} key={p.name}>
            <p>{p.name}</p>
            <BsTrash
              className={icon}
              onClick={() => {
                setEmailToDelete(p);
                setOpen(true);
              }}
            />
          </div>
        ))}
      </div>

      {/* Recipients Form */}
      <div className={titleMargin}>
        <Title title={translations.loginPermissionsAddEmailsTitle} />
      </div>
      <GeneralInfoBox text={translations.loginPermissionsAddEmailsDescription} />
      <form onSubmit={handleRecipientsSubmit(submitRecipientsForm)}>
        <div className={row}>
          <input
            name={`email`}
            id={'email'}
            className={`primary ${whiteInput}`}
            ref={registerRecipients}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
          <p className={inputError}>{recipientsErrors?.email?.message ? translations.errorOnEmailField : null}</p>
        </div>

        <button className="blue" type="submit" disabled={false}>
          {translations.loginPermissionsSubmitRecipients}
        </button>
      </form>

      {/* Cancel permissions section */}
      <div className={titleMargin}>
        <Title title={translations.loginPermissionsCancelEmailsTitle} />
      </div>
      <GeneralInfoBox text={translations.loginPermissionsCancelEmailsDescription} />
      <div className={emailSection}>
        {reminderInvites.map((ri) => {
          return (
            <div className={emailRow} key={ri.email}>
              <p>{ri.email}</p>
              <BsTrash
                className={icon}
                onClick={() => {
                  handleDeleteReminderInvite(ri.id).then(() => {
                    fetchReminderInvites();
                  });
                }}
              />
            </div>
          );
        })}
      </div>

      <AlertModal
        onClose={onClose}
        isOpen={isOpen}
        onLeftButtonClick={onClose}
        onRightButtonClick={handleDeletePermission}
        header={translations.loginPermissionsModalTitle}
        description={emailToDelete?.name ?? ''}
        leftButton={translations.cancel}
        rightButton={translations.loginPermissionsModalRightButton}
      />
    </AdSection>
  );
};
