import useGetTranslations from '../hooks/useGetTranslations';
import Modal from './Modal';
import { convertKeysToCamelCase, toCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/QrCodeModal.module.scss';
import { useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { useApi } from '../hooks/useApi';
import { QR_CODES_API } from '../constants/routes';
import { API_METHOD_GET } from '../constants/api';
import { toast } from 'react-toastify';

interface QrCodeModalProps {
  isOpen: boolean;
  handleOpen: (value: boolean) => void;
  instrumentId: number | undefined;
  qrCode: string | undefined;
}

export const QrCodeModal = ({ isOpen, handleOpen, qrCode, instrumentId }: QrCodeModalProps) => {
  const { wrapper, title, content, btnContainer, option } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const [pianosToPrint, setPianosToPrint] = useState<'this' | 'all'>('this');
  const { handleApi } = useApi();

  const onSave = async () => {
    if (pianosToPrint === 'all') {
      const { message, code } = await handleApi<any>(API_METHOD_GET, QR_CODES_API);

      if (code === 200) {
        toast.success(translations[toCamelCase(message.replace('api.', ''))]);
        handleOpen(false);
        return;
      }
      toast.error(translations.SomethingWentWong);
      handleOpen(false);
      return;
    }

    if (qrCode === undefined) {
      // if single instrument is selected, but has no qr code
      toast.error(translations.SomethingWentWong);
      return;
    }

    downloadImage(qrCode);

    handleOpen(false);
  };

  const downloadImage = async (imageUrl: string) => {
    try {
      const a = document.createElement('a');
      a.href = imageUrl;
      a.target = '_blank';
      a.setAttribute('download', `qr_code_image_${instrumentId}.png`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        handleOpen(false);
      }}
      content={
        <div className={wrapper}>
          <p className={title}>{translations.qrCodeModalTitle}</p>
          <div className={content}>
            <div
              className={`radio ${option}`}
              onClick={() => {
                setPianosToPrint('this');
              }}
            >
              <div className="radio-container">
                <BsCheck className={`radio-item ${pianosToPrint === 'this' && 'radio-item-selected'}`} />
              </div>
              <p>{translations.qrCodeModalThisPiano}</p>
            </div>
            <div
              className={`radio ${option}`}
              onClick={() => {
                setPianosToPrint('all');
              }}
            >
              <div className="radio-container">
                <BsCheck className={`radio-item ${pianosToPrint === 'all' && 'radio-item-selected'}`} />
              </div>
              <p>{translations.qrCodeModalAllPianos}</p>
            </div>
          </div>
          <div className={btnContainer}>
            <button type="button" className="mixed" onClick={onSave}>
              {translations.qrCodeModalSave}
            </button>
          </div>
        </div>
      }
    />
  );
};
