import InstrumentCard from '../InstrumentCard';
import { useApi } from '../../hooks/useApi';
import {
  FETCH_GUEST_INSTRUMENT,
  FETCH_INSTRUMENT,
  INSTRUMENT_DETAILS,
  REQUEST_SERVICE_GUEST_INSTRUMENT,
} from '../../constants/routes';
import { API_METHOD_GET, API_METHOD_POST } from '../../constants/api';
import { createRef, useEffect, useState } from 'react';
import { IInstrumentDetail } from '../../interfaces/components/Instrument';
import IdHeader from '../IdHeader';
import style from '../../assets/scss/pages/GuestInstrumentDetails.module.scss';
import { convertKeysToCamelCase, getBase64 } from '../../helpers/baseHelper';
import AdSection from '../AdSection';
import useGetTranslations from '../../hooks/useGetTranslations';
import LatestInfo from '../LatestInfo';
import Title from '../Title';
import { GeneralInfoBox } from '../info/GeneralInfoBox';
import useYupValidationResolver from '../../hooks/useYupValidationResolver';
import { getRequestServiceValidation } from '../../helpers/validationSchemaHelper';
import { useForm } from 'react-hook-form';
import { RequestServiceForm } from '../../interfaces/components/GeneralInstrumentDetail';
import { BsCameraFill } from 'react-icons/bs';
import { nanoid } from 'nanoid';
import { ReactCropperElement } from 'react-cropper';
import useGeneral from '../../hooks/useGeneral';
import { ImageCropper } from '../ImageCropper';
import AlertModal from '../AlertModal';
import { TOKEN_DATA_KEY } from '../../constants/login';
import { toast } from 'react-toastify';

export const GuestInstrumentDetails = () => {
  const id = window.location.href.split('/').pop();
  const { handleApi } = useApi();
  const [instrument, setInstrument] = useState<IInstrumentDetail>();
  const {
    row,
    attributeIndented,
    grayCard,
    whiteInput,
    labelMargin,
    rowCenter,
    titleMargin,
    icon,
    errorMessage,
    bottom,
  } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const resolver = useYupValidationResolver(getRequestServiceValidation());
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RequestServiceForm>({ resolver, shouldUnregister: false });
  const [cropUploadImage, setCropUploadImage] = useState<string>('');
  //prettier-ignore
  const [uploadImage, setUploadImage] = useState<{ content: string; name: string }>(
    {} as { content: string; name: string }
  );
  const [isOpen, setOpen] = useState(false);
  const [submitData, setSubmitData] = useState<RequestServiceForm | null>(null);
  const cropperRef = createRef<ReactCropperElement>();
  const { resizeFile, finishCrop, handleRedirect } = useGeneral();

  const onClose = () => {
    setOpen(false);
  };

  const handleSend = async () => {
    if (submitData === null || !id) {
      return;
    }

    const { code } = await handleApi(API_METHOD_POST, REQUEST_SERVICE_GUEST_INSTRUMENT.replace(':id', id), {
      message: submitData.message,
      requesterName: submitData.requestedBy,
      requesterPhone: submitData?.phone,
      requesterEmail: submitData?.email,
      attachment: uploadImage.content,
      attachmentName: uploadImage.name,
    });
    if (code === 200) {
      reset();
      toast.success(translations.serviceRequested);
      setOpen(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  async function fetchInstrument() {
    if (localStorage.getItem(TOKEN_DATA_KEY)) {
      //prettier-ignore
      const { code } = await handleApi(
        API_METHOD_GET,
        FETCH_INSTRUMENT.replace(':id', id as string),
        undefined,
        undefined,
        true
      );
      if (code === 200) {
        localStorage.setItem('fromGuest', 'true');
        handleRedirect(INSTRUMENT_DETAILS.replace(':id', id as string));
      }
    }
    const { data } = await handleApi(API_METHOD_GET, FETCH_GUEST_INSTRUMENT.replace(':id', id as string));
    setInstrument(data);
  }

  const handleUploadCropImage = (fileString: string | ArrayBuffer | null, name: string) => {
    name;
    if (typeof fileString === 'string') {
      setCropUploadImage(fileString);
    }
  };
  const handleCancelUploadCrop = () => {
    setCropUploadImage('');
  };

  const onLoadPictures = (fileString: string | ArrayBuffer | null, name: string) => {
    if (fileString) {
      setUploadImage({ content: fileString as string, name: name });
    }
  };

  const handleFinishUploadCrop = async () => {
    const resized = await finishCrop(cropperRef);

    if (resized) {
      onLoadPictures(resized as any, `${nanoid()}}.png`);
      setCropUploadImage('');
    }
  };

  const onPictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const amountOfFiles = files.length;
      // Prevent adding files if limit exceeded
      for (let i = 0; i < amountOfFiles; i++) {
        if (files.length === 1) {
          getBase64(files[i], handleUploadCropImage);
          return;
        }
        const image = await resizeFile(files[i]);
        onLoadPictures(image as any, `resized${nanoid()}.png`);
      }
    }
  };

  const submitForm = (data: RequestServiceForm) => {
    setSubmitData(data);
    setOpen(true);
  };

  useEffect(() => {
    fetchInstrument();
  }, []);

  return (
    <AdSection topPadding>
      {<IdHeader isInstrument={true} id={instrument?.id} />}
      <InstrumentCard isSimple={true} {...instrument} />

      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormRoom}</p>
        <p className={`bubble-instrument`}>{instrument?.room}</p>
      </div>

      <div className={row}>
        <p className={attributeIndented}>{translations.instrumentDetailFormSerial}</p>
        <p className="bubble-instrument">{instrument?.serialNumber}</p>
      </div>

      <div className={grayCard}>
        <Title title={translations.instrumentDetailFormServiceTitle} />
        <div className={row}>
          <p className={attributeIndented}>{translations.instrumentDetailFormLast}</p>
          <p className={`bubble-date ${whiteInput}`}>{instrument?.latestService?.lastServiceDate}</p>
        </div>
        <div className={row}>
          <p className={`bubble-instrument ${whiteInput}`}>{instrument?.latestService?.title}</p>
        </div>
        <LatestInfo
          needsWhiteInputs
          temperature={instrument?.latestService?.temperature}
          frequency={instrument?.latestService?.frequency}
          humidity={instrument?.latestService?.humidity}
        />
      </div>

      {/* Request form */}
      <form onSubmit={handleSubmit(submitForm)}>
        <div className={titleMargin}>
          <Title title={translations.requestServiceFormMessageTitle} />
        </div>
        <GeneralInfoBox text={translations.requestServiceFormMessageDescription} />

        <div className={row}>
          <label htmlFor="message">{translations.requestServiceFormMessageLabel}</label>
        </div>
        <textarea id="message" name="message" rows={8} ref={register} />
        <p className={errorMessage}>{errors.message?.message && translations.required}</p>

        <div className={row}>
          <input id="image" name="image" type="file" accept="image/*" onChange={onPictureChange} />
          <label htmlFor="image" className={row}>
            {translations.requestServiceFormAttachmentsLabel}
            <BsCameraFill className={icon} />
          </label>
          {Object.keys(uploadImage).length > 0 ? '( 1 )' : ''}
        </div>

        <>
          {cropUploadImage !== '' && cropperRef && cropUploadImage && (
            <ImageCropper
              cropperRef={cropperRef}
              cropImage={cropUploadImage}
              handleFinishCrop={handleFinishUploadCrop}
              cropText={translations.crop}
              cancelText={translations.cancel}
              handleCancel={handleCancelUploadCrop}
            />
          )}
        </>

        <div className={titleMargin}>
          <Title title={translations.requestServiceFormRequestedByTitle} />
        </div>

        <div className={row}>
          <textarea id="requestedBy" name="requestedBy" rows={2} ref={register} />
        </div>
        <p className={errorMessage}>{errors.requestedBy?.message && translations.required}</p>

        <div className={row}>
          <GeneralInfoBox text={translations.requestServiceFormRequestedByDescription} />
        </div>

        <div className={row}>
          <label htmlFor="phone" className={labelMargin}>
            {translations.requestServiceFormRequestedByPhoneLabel}
          </label>
          <input
            name="phone"
            id="phone"
            className={`primary ${whiteInput}`}
            ref={register}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        </div>

        <div className={row}>
          <label htmlFor="email" className={labelMargin}>
            {translations.requestServiceFormRequestedByEmailLabel}
          </label>
          <input
            name="email"
            id="email"
            className={`primary ${whiteInput}`}
            ref={register}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        </div>
        <p className={errorMessage}>{errors.email?.message && translations.errorOnEmailField}</p>

        <div className={`${rowCenter} ${bottom}`}>
          <button className="blue" type="submit" disabled={false}>
            {translations.requestServiceFormSend}
          </button>
        </div>
      </form>
      <AlertModal
        onClose={onClose}
        isOpen={isOpen}
        onLeftButtonClick={onClose}
        onRightButtonClick={handleSend}
        header={''}
        description={translations.requestServiceModalTitle}
        leftButton={translations.cancel}
        rightButton={translations.requestServiceModalRightButton}
      />
    </AdSection>
  );
};
