import { useSelector } from 'react-redux';
import useProfileCard from '../../hooks/useProfileCard';
import ProfileCard from '../ProfileCard';
import { IReduxStore } from '../../interfaces/IGeneral';
import AdSection from '../AdSection';
import Title from '../Title';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';
import useGetTranslations from '../../hooks/useGetTranslations';
import style from '../../assets/scss/pages/LoginPermissions.module.scss';
import { GeneralInfoBox } from '../info/GeneralInfoBox';
import { MyEmailPermission, Permission } from '../../interfaces/components/LoginPermissions';
import { useEffect, useState } from 'react';
import useSetHeader from '../../hooks/useSetHeader';
import {
  COMPANY_GET_PERMISSIONS,
  COMPANY_GET_REMINDER_PERMISSIONS,
  COMPANY_INSTITUTE_LOGIN,
  HOME_PAGE,
  PROFILE,
} from '../../constants/routes';
import useGeneral from '../../hooks/useGeneral';
import { useApi } from '../../hooks/useApi';
import { API_METHOD_GET, API_METHOD_POST } from '../../constants/api';
import { TOKEN_DATA_KEY } from '../../constants/login';

export const MyPermissions = () => {
  const { uploadImage } = useProfileCard();
  const { profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { titleMargin, emailRow, emailSection } = convertKeysToCamelCase(style);
  const { translations } = useGetTranslations();
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [reminderPermissions, setReminderPermissions] = useState<MyEmailPermission[]>([]);
  const { handleApi } = useApi();
  const { handleRedirect } = useGeneral();

  const fetchPermissions = async () => {
    const { data } = await handleApi(API_METHOD_GET, COMPANY_GET_PERMISSIONS);
    setPermissions(data);
  };

  const fetchReminderPermissions = async () => {
    const { data } = await handleApi(API_METHOD_GET, COMPANY_GET_REMINDER_PERMISSIONS);
    setReminderPermissions(data);
  };

  const handleLogin = async (email: string) => {
    const { data, code } = await handleApi(API_METHOD_POST, COMPANY_INSTITUTE_LOGIN, { email });
    if (code === 200) {
      localStorage.setItem(TOKEN_DATA_KEY, JSON.stringify(data));
      handleRedirect(HOME_PAGE);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (profile?.userType !== 'company') {
      handleRedirect(PROFILE);
      return;
    }
    fetchPermissions();
    fetchReminderPermissions();
  }, [profile]);

  useSetHeader(() => {
    handleRedirect(PROFILE);
  }, translations.companyLoginPermissions);

  return (
    <AdSection>
      <ProfileCard uploadImage={uploadImage} clientId={profile?.id} />

      <div className={titleMargin}>
        <Title title={translations.companyLoginPermissionTitle} />
      </div>
      <GeneralInfoBox text={translations.companyLoginPermissionDescription} />
      <div className={emailSection}>
        {permissions.map((p) => (
          <div className={emailRow} key={p.name}>
            <p>{p.name}</p>
            <button className="blue" onClick={() => handleLogin(p.email)}>
              {translations.loginLogin}
            </button>
          </div>
        ))}
      </div>

      <div className={titleMargin}>
        <Title title={translations.companyLoginReminderPermissionTitle} />
      </div>
      <GeneralInfoBox text={translations.companyLoginReminderPermissionDescription} />
      <div className={emailSection}>
        {reminderPermissions.map((p) => (
          <div className={emailRow} key={p.name}>
            <p>{p.name}</p>
          </div>
        ))}
      </div>
    </AdSection>
  );
};
