import { AiFillInfoCircle, AiFillPhone, AiOutlinePlus } from 'react-icons/ai';
import { MdEmail, MdOutlineTextsms } from 'react-icons/md';
import { GiRotaryPhone } from 'react-icons/gi';
import { useSelector } from 'react-redux';

import Title from './Title';
import LeaveModal from './LeaveModal';
import SuggestionSearch from './SuggestionSearch';
import style from '../assets/scss/components/ClientsForm.module.scss';
import { convertKeysToCamelCase, scrollToError } from '../helpers/baseHelper';
import ClientsInstruments from './ClientsInstruments';
import { IClientsForm } from '../interfaces/components/Clients';
import { IReduxStore } from '../interfaces/IGeneral';
import useGetTranslations from '../hooks/useGetTranslations';
import CountryDropdown from './CountryDropdown';
import useGetCountries from '../hooks/useGetCountries';
import { BsCheck } from 'react-icons/bs';
import { professionChecker, professionTranslation } from '../utils/roleChecker';
import { GeneralInfoBox } from './info/GeneralInfoBox';

function ClientsForm(props: IClientsForm): JSX.Element {
  const {
    row,
    input,
    inputLine,
    inputLeft,
    icon,
    phoneContainer,
    btnContainer,
    rowMemo,
    errorMessage,
    doubleRow,
    leftP,
    connectInstrument,
    indented,
    infoContainer,
    infoImage,
    infoDescription,
    typeTitle,
    companyName,
    infoContainerNoPadding,
  } = convertKeysToCamelCase(style);
  const {
    register,
    handleSubmit,
    selectedInstruments,
    onMatchInstrument,
    onDeleteClick,
    errors,
    control,
    disableSave,
    showLeaveWarning,
    onTypeChange,
    clientType,
  } = props;
  const { instruments, profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const { countries } = useGetCountries();

  if (errors) {
    scrollToError(errors);
  }

  return (
    <form onSubmit={handleSubmit}>
      {showLeaveWarning && <LeaveModal />}
      <Title title={translations.clientsFormTypeTitle} />

      <div className={infoContainerNoPadding}>
        <AiFillInfoCircle className={infoImage} />
        <p className={infoDescription}>
          {
            /*prettier-ignore*/
            profile?.userType === 'institute'
              ? translations.clientFormClientTipToeInstitute
              : professionChecker()
                ? translations.clientFormClientTipToe
                : translations.clientFormTechnicianTipToe
          }
        </p>
      </div>

      <div className={indented}>
        <p className={typeTitle}>{translations[professionTranslation('clientsFormTypeQuestion', true)]}</p>
        <div className="radio">
          <div className="radio-container">
            <p>
              {
                /*prettier-ignore*/
                profile?.userType === 'institute'
                  ? translations.clientsFormTypePrivatePersonInstitute
                  : professionChecker()
                    ? translations.clientsFormTypePrivatePerson
                    : translations.clientsFormTypePrivatePersonTechnician
              }
            </p>
            <BsCheck
              className={`radio-item ${indented} ${clientType === 'private' && 'radio-item-selected'}`}
              onClick={() => {
                onTypeChange('private');
              }}
            />
          </div>
        </div>
        <div className="radio">
          <div className="radio-container">
            <p>
              {
                /*prettier-ignore*/
                profile?.userType === 'institute'
                  ? translations.clientsFormTypeCompanyInstitute
                  : professionChecker()
                    ? translations.clientsFormTypeCompany
                    : translations.clientsFormTypeCompanyTechnician
              }
            </p>
            <BsCheck
              className={`radio-item ${indented} ${clientType === 'company' && 'radio-item-selected'}`}
              onClick={() => {
                onTypeChange('company');
              }}
            />
          </div>
        </div>
      </div>

      <Title title={professionChecker() ? translations.clientsFormName : translations.clientsFormNameTechnician} />
      <div className={indented}>
        {errors.firstName && <p className={errorMessage}>{translations.mandatoryField}</p>}
        <div className={clientType !== 'company' ? row : ''}>
          <p>{clientType !== 'company' ? translations.clientsFormFirstName : translations.clientTypeCompanyName}</p>
          <input
            type="text"
            className={`${input} ${clientType === 'company' && companyName}`}
            ref={register}
            name="firstName"
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        </div>
        {clientType !== 'company' && (
          <>
            {errors.lastName && <p className={errorMessage}>{translations.mandatoryField}</p>}
            <div className={row}>
              <p>{translations.clientsFormLastName}</p>
              <input
                type="text"
                className={input}
                ref={register}
                name="lastName"
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            </div>
          </>
        )}
        <p>{clientType !== 'company' ? translations.clientsFormMemoNameLabel : translations.contactName}</p>
        {profile?.userType === 'institute' && <GeneralInfoBox text={translations.instituteClientMemo} />}
        <div className={rowMemo}>
          <input
            type="text"
            className={`${input} ${inputLine}`}
            placeholder={
              profile?.userType === 'institute'
                ? translations.clientsFormMemoNameInstitute
                : translations.clientsFormMemoName
            }
            ref={register}
            name="nick"
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        </div>
      </div>

      <div style={{ display: professionChecker() ? 'block' : 'none' }}>
        <Title title={translations.clientsFormAddress} />
        <div className={indented}>
          {errors.street && <p className={errorMessage}>{translations.mandatoryField}</p>}
          <div className={row}>
            <input
              type="text"
              className={`${input} ${inputLine}`}
              placeholder={translations.clientsFormStreet}
              ref={register}
              name="street"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
          {(errors.city || errors.region) && (
            <div className={doubleRow}>
              <p className={errorMessage}>{errors.city && translations.mandatoryField}</p>
              <p className={`${errorMessage} ${leftP}`}>{errors.region && translations.mandatoryField}</p>
            </div>
          )}
          <div className={row}>
            <input
              type="text"
              className={`${input} ${inputLeft}`}
              placeholder={translations.clientsFormCity}
              ref={register}
              name="city"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
            <input
              type="text"
              className={input}
              placeholder={translations.clientsFormState}
              ref={register}
              name="region"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
          {errors.country && (
            <div className={doubleRow}>
              <p className={errorMessage} />
              <p className={`${errorMessage} ${leftP}`}>{errors.country && translations.mandatoryField}</p>
            </div>
          )}
          <div className={row}>
            <input
              type="text"
              className={`${input} ${inputLeft}`}
              placeholder={translations.clientsFormPostalCode}
              ref={register}
              name="postcode"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
            <CountryDropdown
              control={control}
              countries={countries}
              title={false}
              placeholder={translations.clientsFormCountry}
              textWithWrap
            />
          </div>
        </div>
      </div>
      <div className={indented}>
        <div className={phoneContainer}>
          <div className={row}>
            <AiFillPhone className={icon} />
            <AiOutlinePlus className={icon} />
            <MdOutlineTextsms className={icon} />
            <input
              type="text"
              className={input}
              placeholder={translations.clientsFormMobileNumber}
              ref={register}
              name="phone1"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
          <div className={row}>
            <GiRotaryPhone className={icon} />
            <input
              type="text"
              className={input}
              placeholder={translations.clientsFormLinePhone}
              ref={register}
              name="phone2"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
          {errors.email && <p className={errorMessage}>{translations.errorOnEmailField}</p>}
          <div className={row}>
            <MdEmail className={icon} />
            <input
              type="text"
              className={input}
              placeholder={translations.clientsFormEmailAddress}
              ref={register}
              name="email"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
        </div>
      </div>

      <Title title={translations.clientsFormInstrument} />
      <div className={indented}>
        <p className={connectInstrument}>{translations.instrumentDisconnectTitle}</p>
        <SuggestionSearch
          suggestions={instruments.map((instr) => ({
            name: instr.brand + ', ' + instr.category + ', ' + translations.generalId + ': ' + instr.id,
            id: instr.id,
          }))}
          placeholder={translations.instrumentSearchPlaceholder}
          onMatch={onMatchInstrument}
          multiselect
        />
        {selectedInstruments.length > 0 && (
          <div className={infoContainer}>
            <AiFillInfoCircle className={infoImage} />
            <p className={infoDescription}>{translations.instrumentChooseDescription}</p>
          </div>
        )}
        <ClientsInstruments isEditing={true} selectedInstruments={selectedInstruments} onDeleteClick={onDeleteClick} />
      </div>
      <Title title={translations.clientsFormNote} />
      <div className={indented}>
        <textarea rows={8} ref={register} name="note" />
      </div>
      <div className={btnContainer}>
        <button type="submit" className="blue" disabled={disableSave}>
          {profile?.userType === 'institute'
            ? translations.clientsFormSaveInstituteClient
            : translations.clientsFormSaveClient}
        </button>
      </div>
    </form>
  );
}

export default ClientsForm;
