import { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { axiosGeneral } from '../api/axiosConfig';
import { IReduxStore, IUseApi, TAxiosMethod } from '../interfaces/IGeneral';
import { SET_IS_LOADING, SET_PROMISE } from '../constants/reduxActions';
import { TOKEN_DATA_KEY } from '../constants/login';
import { toast, ToastOptions } from 'react-toastify';
import { NOT_ALLOWED, NO_ACTIVE_SUBSCRIPTION, SOMETHING_WENT_WRONG } from '../constants/errors';
import NoActiveSubscriptionNotification, {
  noActiveSubscriptionOptions,
} from '../components/NoActiveSubscriptionNotification';
import { toCamelCase } from '../helpers/baseHelper';
import useGetTranslations from './useGetTranslations';
import { HOME_PAGE, PROFILE } from '../constants/routes';
import { useHistory } from 'react-router';

export function useApi(): IUseApi {
  const { isLoading } = useSelector((store: IReduxStore) => store.defaultReducer);
  const dispatch = useDispatch();
  const { translations } = useGetTranslations();
  const history = useHistory();

  const user = localStorage.getItem(TOKEN_DATA_KEY);

  type SupportedLanguages = 'en' | 'de' | 'fr' | 'es' | 'pl' | 'it';

  const fallbackTranslations: Record<SupportedLanguages, { notAllowed: string }> = {
    en: {
      notAllowed:
        'You are trying to open a content that does not belong to your account. Please log in to the desired account, which you can do on this page under "My login permission" or via the "log out" link.',
    },
    de: {
      notAllowed:
        'Sie versuchen, Inhalte zu öffnen, die nicht zu Ihrem Konto gehören. Bitte melden Sie sich mit dem gewünschten Konto an. Dies können Sie auf dieser Seite unter "Meine Login-Berechtigung" oder über den Link "Abmelden" tun.',
    },
    fr: {
      notAllowed: `Vous essayez d'accéder à un contenu qui n'appartient pas à votre compte. Veuillez vous connecter avec le compte souhaité, ce que vous pouvez faire sur cette page sous "Ma permission de connexion" ou via le lien "Se déconnecter".`,
    },
    es: {
      notAllowed: `Vous essayez d'accéder à un contenu qui n'appartient pas à votre compte. Veuillez vous connecter avec le compte souhaité, ce que vous pouvez faire sur cette page sous "Ma permission de connexion" ou via le lien "Se déconnecter".`,
    },
    pl: {
      notAllowed: `Próbujesz otworzyć treść, która nie należy do Twojego konta. Zaloguj się na wybrane konto, co możesz zrobić na tej stronie w sekcji „Moje uprawnienia logowania” lub za pomocą linku „Wyloguj się”.`,
    },
    it: {
      notAllowed: `Stai cercando di accedere a un contenuto che non appartiene al tuo account. Effettua l'accesso all'account desiderato, cosa che puoi fare in questa pagina sotto "Il mio permesso di accesso" o tramite il link "Esci".`,
    },
  };

  const somethingWentWrong: ToastOptions = {
    toastId: 'someting-went-wrong',
    autoClose: 6000,
  };

  const notAllowedOptions: ToastOptions = {
    toastId: 'not-allowed-error',
    autoClose: 8000,
  };

  function handleApi<T>(method: TAxiosMethod, url: string, data?: T, noLoading?: boolean, noError?: boolean) {
    const promise = new Promise<T>((resolve, reject) => {
      let headers = {};
      if (user) headers = { authorization: `Bearer ${JSON.parse(user).access_token}` };
      let config: AxiosRequestConfig = { method, url, headers };
      if (data) {
        config = { ...config, data };
      }

      const request = axiosGeneral(config);
      if (!isLoading && !noLoading) {
        dispatch({ type: SET_IS_LOADING, payload: true });
      }

      request
        .then((response) => {
          if (response.data.code >= 400 && !noError) {
            showError(response.data.message, response.data.code);
          }
          resolve(response.data);
        })
        .catch((error) => reject(error.response));
    });
    dispatch({ type: SET_PROMISE, payload: promise });

    return promise;
  }

  const showToast = (message: string) => {
    Object.keys(translations).length > 0 && toast.error(message, somethingWentWrong);
  };

  function showError(error: any, code: number): void {
    switch (error) {
      case NO_ACTIVE_SUBSCRIPTION:
        toast.error(<NoActiveSubscriptionNotification />, noActiveSubscriptionOptions);
        break;
      case SOMETHING_WENT_WRONG:
        showToast(translations[toCamelCase(error)]);
        break;
      case NOT_ALLOWED:
        if (user) {
          const language = JSON.parse(user).language_code || 'en';
          const supportedLanguages: SupportedLanguages[] = ['en', 'de', 'fr', 'es', 'pl', 'it'];
          const asd = supportedLanguages.includes(language as SupportedLanguages)
            ? (language as SupportedLanguages)
            : 'en';
          const fallbackMessage = fallbackTranslations[asd]?.notAllowed || fallbackTranslations.en.notAllowed;
          toast.error(fallbackMessage, notAllowedOptions);
        }
        history.push({
          pathname: PROFILE,
        });
        window.scrollTo(0, 0);
        break;
      default:
        if (translations[toCamelCase(error)] && translations[toCamelCase(error)].length > 3) {
          showToast(translations[toCamelCase(error)]);
          break;
        }
        if (code === 404) {
          history.replace(HOME_PAGE);
          break;
        }

        if (code === 408) {
          showToast(translations.connectionSlow);
          break;
        }

        if (error !== null) {
          //prettier-ignore
          toast.error(
            translations[toCamelCase(error.replace('api.', ''))]
              ? translations[toCamelCase(error.replace('api.', ''))]
              : error,
            somethingWentWrong
          );
        }
        break;
    }
  }

  return { handleApi };
}
