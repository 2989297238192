import { useSelector } from 'react-redux';
import { IoMdMail } from 'react-icons/io';
import { RefObject, useRef } from 'react';
import { MdModeEditOutline } from 'react-icons/md';

import style from '../assets/scss/components/ProfileCard.module.scss';
import { convertKeysToCamelCase } from '../helpers/baseHelper';
import { IProfileCard } from '../interfaces/components/ProfileCard';
import { IReduxStore } from '../interfaces/IGeneral';
import noImage from '../assets/images/userplaceholder.jpg';
import useGetTranslations from '../hooks/useGetTranslations';
import { FaUser } from 'react-icons/fa';

function ProfileHeader(props: IProfileCard): JSX.Element {
  const {
    wrapper,
    textContainer,
    name,
    country,
    emailContainer,
    icon,
    image,
    imageContainer,
    section,
    dataContainer,
    client,
    email,
    userType,
  } = convertKeysToCamelCase(style);
  const { isEditing = false, handleImageChange, uploadImage, clientId } = props;
  const { profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const image1Ref = useRef(null);
  const image2Ref = useRef(null);
  const { translations } = useGetTranslations();

  const onError = (ref: RefObject<HTMLImageElement>) => {
    if (ref && ref.current) ref.current.src = noImage;
  };

  //prettier-ignore
  return (
    <div className={wrapper}>
      <section className="small">
        {clientId && (
          <div className={`${client} h4`}>
            {translations.myAccountUserId}: {clientId}
          </div>
        )}
      </section>
      <section className={`small ${section}`}>
        <div className={imageContainer}>
          {handleImageChange ? (
            <>
              <input
                id="file"
                name="file"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                disabled={!isEditing}
              />
              <label className={image} htmlFor="file">
                {uploadImage ? (
                  <img
                    src={uploadImage}
                    className={image}
                    ref={image2Ref}
                    onError={() => {
                      onError(image2Ref);
                    }}
                  />
                ) : (
                  <img
                    src={uploadImage}
                    className={image}
                    onError={() => {
                      onError(image1Ref);
                    }}
                    ref={image1Ref}
                  />
                )}
                {isEditing && <MdModeEditOutline className="image-edit-icon" />}
              </label>
            </>
          ) : (
            <img
              src={profile?.profilePicture}
              className={image}
              onError={() => {
                onError(image1Ref);
              }}
              ref={image1Ref}
            />
          )}
        </div>

        <div className={dataContainer}>
          <div className={textContainer}>
            <p className={name}>
              {profile?.firstName} {profile?.lastName}
            </p>
            <p className={country}>{profile?.country}</p>
          </div>

          <div className={emailContainer}>
            <IoMdMail className={icon} />
            <p className={email}>{profile?.email}</p>
          </div>
          <div className={emailContainer}>
            <FaUser className={icon} />
            <p className={userType}>
              {profile?.userType === 'private'
                ? translations.profilePrivate
                : profile?.userType === 'company'
                  ? translations.profileCompany
                  : translations.profileInstitute
              }
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProfileHeader;
