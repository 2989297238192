import { useSelector } from 'react-redux';
import ServiceProjectsHeader from '../ServiceProjectsHeader';
import HomeSearchHeader from '../HomeSearchHeader';
import useGetServices from '../../hooks/useGetServices';
import FilterElement from '../FilterElement';
import GlobalSearchElement from '../GlobalSearchElements';
import { IReduxStore } from '../../interfaces/IGeneral';
import AdSection from '../AdSection';
import useGetTranslations from '../../hooks/useGetTranslations';
import { INSTRUMENT_SERVICES, SERVICE_DETAIL } from '../../constants/routes';
import { IServiceListElement } from '../../interfaces/components/Service';
import InfiniteScroll from 'react-infinite-scroll-component';
import { filterDuplicateNames } from '../../helpers/baseHelper';
import useMobile from '../../hooks/useMobile';
import { paddingForInfiniteScroll } from '../../constants/infiniteScrollStyle';
import UnfinishedServiceFilter from '../UnfinishedServiceFilter';
import { ServiceCardTypes } from '../../constants/ServiceCard';
import useGeneral from '../../hooks/useGeneral';
import { InstrumentServiceCard } from '../InstrumentServiceCard';
import { ServiceIconInfo } from '../ServiceIconInfo';
import ActiveServiceCard from '../ActiveServiceCard';
import useChooseTuner from '../../hooks/useChooseTuner';
import { useMemo } from 'react';
import { professionChecker } from '../../utils/roleChecker';
import useSetHeader from '../../hooks/useSetHeader';

function Services(): JSX.Element {
  const {
    services,
    total,
    servicesClients,
    servicesInstruments,
    toggle,
    handleToggle,
    nextPage,
    handleActiveServiceChecked,
    activeServiceChecked,
    activeServices,
  } = useGetServices();
  const { globalSearch, profile } = useSelector((store: IReduxStore) => store.defaultReducer);
  const { translations } = useGetTranslations();
  const { isMobile } = useMobile();
  const { tuners } = useChooseTuner();
  const { handleRedirect } = useGeneral();
  const isTuner = useMemo(() => professionChecker(), []);

  const userData = localStorage.getItem('userData');
  const title = userData ? userData.split(',')[0] : 'Piano Service App';

  useSetHeader(() => {}, title, undefined, false, true);

  //prettier-ignore
  return (
    <main>
      <HomeSearchHeader
        hideFilters={!isTuner}
        sortField="brand"
        parentPage={activeServiceChecked ? "activeServiceList" : "serviceList"}
        sortTitle={{
          ascending: translations.sortInstrumentsAscending,
          descending: translations.sortInstrumentsDescending,
        }}
        passAllSortToActiveFilter
        filters={
          <>
            {!activeServiceChecked && <UnfinishedServiceFilter toggle={toggle} handleToggle={handleToggle} />}
            <FilterElement name={translations.byCategory} field="category" isSelect isNumber={false} />
            <FilterElement
              name={translations.byTuner}
              data={filterDuplicateNames(tuners)}
              field="tuners"
              isNumber={true}
            />
            <FilterElement
              name={translations.byInstrument}
              data={filterDuplicateNames(
                servicesInstruments.map((instrument) => ({ 
                  id: instrument.id,
                  name: instrument.brand,
                }))
              )}
              field="instruments"
              isNumber={false}
            />
            <FilterElement
              name={ profile?.userType !== 'institute' ? translations.byClients : translations.byInstituteClient}
              field="clients"
              data={filterDuplicateNames(
                servicesClients.map((cli) => ({ id: cli.id, name: `${cli.firstName} ${cli.lastName}, ID: ${cli.id}` }))
              )}
              isNumber={true}
            />
            <FilterElement
              name={translations.byCity}
              field="city"
              data={filterDuplicateNames(servicesClients.map((cli) => ({ id: cli.id, name: cli.city })))}
              isNumber={false}
            />
            <FilterElement
              name={translations.byRegion}
              field="region"
              data={filterDuplicateNames(servicesClients.map((cli) => ({ id: cli.id, name: cli.region })))}
              isNumber={false}
            />
            <FilterElement
              name={translations.byPostcode}
              field="postcode"
              data={filterDuplicateNames(servicesClients.map((cli) => ({ id: cli.id, name: cli.postcode })))}
              isNumber={false}
            />
            <FilterElement
              name={translations.byCountry}
              field="country"
              data={filterDuplicateNames(servicesClients.map((cli) => ({ id: cli.id, name: cli.country })))}
              isNumber={false}
            />
          </>
        }
      />
      {Object.keys(globalSearch).length > 0 ? (
        <GlobalSearchElement />
      ) : (
        <>
          <section className="small">
            <ServiceProjectsHeader isServices={true} toggleChecked={activeServiceChecked} onToggleChecked={handleActiveServiceChecked}/>
          </section>

          <AdSection length={total} noPadding>
            <ServiceIconInfo active={activeServiceChecked}  />
            {activeServiceChecked ? <>{isMobile ? (
              <InfiniteScroll
                dataLength={activeServices.length}
                next={nextPage}
                hasMore={true}
                loader={<></>}
                style={{...paddingForInfiniteScroll, marginTop: '20px'}}
              >
                {activeServices.map((as) => {
                  return <ActiveServiceCard key={as.id} service={as} type={ServiceCardTypes.INSTRUMENT_SERVICE}
                    onClick={() => {
                      handleRedirect(SERVICE_DETAIL.replace(':id', as.id.toString()));
                    }}
                    showProjectIcon={as?.projectId !== undefined && as.projectId !== null}
                  />
                })}
              </InfiniteScroll>
            ) : (
              <div style={{marginTop: '20px'}}>
                {activeServices.map((as) => {
                  return <ActiveServiceCard key={as.id} service={as} type={ServiceCardTypes.INSTRUMENT_SERVICE}
                    onClick={() => {
                      handleRedirect(SERVICE_DETAIL.replace(':id', as.id.toString()));
                    }}
                    showProjectIcon={as?.projectId !== undefined && as.projectId !== null}
                  />
                })}
              </div>)}</> : <>{isMobile ? (
              <InfiniteScroll
                dataLength={services.length}
                next={nextPage}
                hasMore={true}
                loader={<></>}
                style={{...paddingForInfiniteScroll, marginTop: '20px'}}
              >
                {services.map((service: IServiceListElement) => (
                  <InstrumentServiceCard
                    key={service.id}
                    isSimple={false}
                    isServiceCard
                    dateInsteadModel
                    date={service?.date}
                    endDate={service.scheduledDate}
                    serviceType="service"
                    {...service}
                    to={INSTRUMENT_SERVICES.replace(':id', service.id.toString())}
                    showProjectIcon={service?.hasProjects}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              services.map((service: IServiceListElement) => (
                <InstrumentServiceCard
                  key={service.id}
                  isSimple={false}
                  isServiceCard
                  dateInsteadModel
                  date={service?.date}
                  endDate={service.scheduledDate}
                  serviceType="service"
                  {...service}
                  to={INSTRUMENT_SERVICES.replace(':id', service.id.toString())}
                  showProjectIcon={service?.hasProjects}
                />
              ))
            )}</> }
          </AdSection>
        </>
      )}
    </main>
  );
}

export default Services;
